<template>
    <div class="tab-pane active" id="assessment-items-list-table" role="tabpanel">
        <div class="tabs-component tabs-component-2 row">
            <ul class="nav nav-tabs nav-tabs-2 float-sm-left col-12 col-md">
                <li @click.prevent="selectCard('RR')"
                    class="tabs-component-tab nav-item"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'RR items and Times', placement:'bottomright'}">
                    <a href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedCard == 'RR'}">RR</a>
                </li>
                <li @click.prevent="selectCard('Repair')"
                    class="tabs-component-tab nav-item"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Repair items and Times', placement:'bottomright'}">
                    <a href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedCard == 'Repair'}">Repair</a>
                </li>
                <li @click.prevent="selectCard('Paint')"
                    class="tabs-component-tab nav-item"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Paint items and Times', placement:'bottomright'}">
                    <a href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedCard == 'Paint'}">Paint</a>
                </li>
                <li @click.prevent="selectCard('Part')"
                    class="tabs-component-tab nav-item"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Parts list and pricing', placement:'bottomright'}">
                    <a href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedCard == 'Part'}">Parts</a>
                </li>
                <li @click.prevent="selectCard('Materials')"
                    class="tabs-component-tab nav-item"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Materials Times', placement:'bottomright'}">
                    <a href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedCard == 'Materials'}">Materials</a>
                </li>
                <li @click.prevent="selectCard('Sublet')" class="tabs-component-tab nav-item">
                    <a href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedCard == 'Sublet'}">Sublet</a>
                </li>
                <li @click.prevent="selectCard('Other')" class="tabs-component-tab nav-item">
                    <a href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedCard == 'Other'}">Other</a>
                </li>
                <li @click.prevent="selectCard('PDR')" class="tabs-component-tab nav-item">
                    <a href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedCard == 'PDR'}">PDR</a>
                </li>
            </ul>
            <div class="nav-tabs__right col d-flex flex-row justify-content-end">
                <div class="form-group row d-flex m-0 align-items-center"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Original Estimate Subtotal', placement:'bottom'}">
                    <label style="margin: 0 10px;" class="col-form-label">Subtotal</label>
                    <div class="">
                        <span class="form-control" style="width: 110px; min-width: 110px; display: inline; line-height: 32px; margin: 0 10px;">{{subTotal|formatMoney}}</span>
                    </div>
                    <!--                        <div class="col-md-6 col pr-0">-->
                    <!--                            <number-formatter-->
                    <!--                                    ref="subtotal"-->
                    <!--                                    type="text"-->
                    <!--                                    v-model="subTotal"-->
                    <!--                                    class="form-control numeric nav-tabs__input-summary"-->
                    <!--                                    format="$0,0.00"-->
                    <!--                                    :isDisabled="true">-->
                    <!--                            </number-formatter>-->
                    <!--                        </div>-->
                </div>
                <div class="orm-group row d-flex m-0 align-items-center"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Adjusted Estimate Subtotal', placement:'bottom'}">
                    <label style="margin: 0 10px;" class="col-form-label ml-2">ADJ Subtotal</label>
                    <div>
                        <span :class="{'white-text': subTotal === adjSubTotal || adjSubtotalPercent == 0}" class="form-control" :style="{color: adjSubTotal > subTotal ? '#2BAE66FF' : 'red'}"
                              style="width: 110px; min-width: 110px; display: inline; line-height: 16px;">{{adjSubTotal|formatMoney}}</span>
                        <span :class="{'white-text': subTotal === adjSubTotal || adjSubtotalPercent == 0}" class="form-control ml-2" :style="{color: adjSubTotal > subTotal ? '#2BAE66FF' : 'red'}"
                              style="width: 110px; min-width: 110px; display: inline; line-height: 16px;">{{ computedSymbolForPercents }}{{adjSubtotalPercent}}%</span>
                    </div>
                    <!--                        <div class="col-md-8 col pr-0 d-flex justify-content-end">-->
                    <!--                            <number-formatter-->
                    <!--                                    ref="adj-subtotal"-->
                    <!--                                    type="text"-->
                    <!--                                    v-model="adjSubTotal"-->
                    <!--                                    class="form-control numeric nav-tabs__input-summary styled-adj col-md-6"-->
                    <!--                                    :class="{'text-danger': !adjusted, 'white-text': adjSubTotal === subTotal}"-->
                    <!--                                    format="$0,0.00"-->
                    <!--                                    :isDisabled="true">-->
                    <!--                            </number-formatter>-->
                    <!--                            <span :class="{'white-text': adjSubTotal === subTotal}" class="form-control ml-2 col-md-6" style="color: red; white-space: nowrap; height: 32px; text-align: center;">{{calculatedAdjSubtotalPercent}}%</span>-->
                    <!--                        </div>-->
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col" id="estimate-item-list">
                <template v-for="(items, card) in itemslist"> <!-- eslint-disable -->
                    <ItemsTypeRR ref='card' v-if="card == 'RR'" :changeItemPart="changeItemPart" :card="selectedCard" :items="items" @addNewLine="openContextMenu" @right-click="handleRightClick" @change="handleItemChange"
                                 :selected="selectedItem" :loading="loading"></ItemsTypeRR>
                    <ItemsTypeRepair ref='card' v-if="card == 'Repair'" :changeItemPart="changeItemPart" :card="selectedCard" :items="items" @addNewLine="openContextMenu" @right-click="handleRightClick" @change="handleItemChange"
                                     :selected="selectedItem" :loading="loading"></ItemsTypeRepair>
                    <ItemsTypePaint ref='card' v-if="card == 'Paint'" :changeItemPart="changeItemPart" :card="selectedCard" :items="items" @addNewLine="openContextMenu" @right-click="handleRightClick" @change="handleItemChange"
                                    :selected="selectedItem" :loading="loading"></ItemsTypePaint>
                    <ItemsTypePart ref='card' v-if="card == 'Part'" :changeItemPart="changeItemPart" :card="selectedCard" :items="items" @addNewLine="openContextMenu" @right-click="handleRightClick" @change="handleItemChange"
                                   :selected="selectedItem" :loading="loading"></ItemsTypePart>
                    <ItemsTypeMaterial ref='card' v-if="card == 'Materials'" :changeItemPart="changeItemPart" :card="selectedCard" :items="items" @addNewLine="openContextMenu" @right-click="handleRightClick"
                                       @change="handleItemChange" :selected="selectedItem" :loading="loading"></ItemsTypeMaterial>
                    <ItemsTypeSublet ref='card' v-if="card == 'Sublet'" :changeItemPart="changeItemPart" :card="selectedCard" :items="items" @addNewLine="openContextMenu" @right-click="handleRightClick" @change="handleItemChange"
                                     :selected="selectedItem" :loading="loading"></ItemsTypeSublet>
                    <ItemsTypeOther ref='card' v-if="card == 'Other'" :changeItemPart="changeItemPart" :card="selectedCard" :items="items" @addNewLine="openContextMenu" @right-click="handleRightClick" @change="handleItemChange"
                                    :selected="selectedItem" :loading="loading"></ItemsTypeOther>
                    <ItemsTypePDR ref='card' v-if="card == 'PDR'" :changeItemPart="changeItemPart" :card="selectedCard" :items="items" @addNewLine="openContextMenu" @right-click="handleRightClick" @change="handleItemChange"
                                  :selected="selectedItem" :loading="loading"></ItemsTypePDR>
                </template>
            </div>
        </div>

        <!-- Context Menu -->
        <vue-context
                v-if="!isUserRoleLawyer"
                ref="item-context-menu"
                class="context-menu-right-click list-group"
                :class="{'list-group-paint':['Paint'].includes(selectedCard)}"
                @open="handleContextMenuOpen"
                @close="handleContextMenuClose"
                :closeOnClick="true"
                :closeOnScroll="true"
                :subMenuOffset="100">

            <ul slot-scope="item" ref="contextMenuForItem" class="d-flex btn-group-vertical">
                <li @keydown="handleContextItemKeydown($event)" @click="handleContextItemClick('approve')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
                    Approve
                </li>
                <li @keydown="handleContextItemKeydown($event)" @click="handleContextItemClick('report')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
                    Report
                </li>
                <li @keydown="handleContextItemKeydown($event)" @click="handleContextItemClick('decline')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
                    Decline
                </li>
                <li @keydown="handleContextItemKeydown($event)" @click="addItemPart(item.data.item, $event)" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
                    Add Line
                </li>
                <li v-if="item && item.data && item.data.item && item.data.item.isManual" @keydown="handleContextItemKeydown($event)" @click="removeItemPart(item.data.item)"
                    class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
                    Remove Line
                </li>
                <li v-show="['RR'].includes(selectedCard)" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
                    Change Rate All
                    <ul type="none" class="subContext">
                        <number-formatter
                                v-model="computedRrAdjRate"
                                type="text"
                                class="form-control numeric text-left font-12 font-nunito--semibold"
                                format="$0,0.00"
                                :isTotalManual="true"
                                @onChange="changeRateAll"
                        >
                        </number-formatter>
                    </ul>
                </li>
                <li v-show="['Repair'].includes(selectedCard)" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
                    Change Rate All
                    <ul type="none" class="subContext">
                        <number-formatter
                                v-model="assessment.repairAdjRate"
                                type="text"
                                class="form-control numeric text-left font-12 font-nunito--semibold"
                                format="$0,0.00"
                                :isTotalManual="true"
                                @onChange="changeRateAll(assessment.repairAdjRate)"
                        >
                        </number-formatter>
                    </ul>
                </li>
                <li v-show="['Paint'].includes(selectedCard)" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
                    Change Rate All
                    <ul type="none" class="subContext">
                        <number-formatter
                                v-model="assessment.paintAdjRate"
                                type="text"
                                class="form-control numeric text-left font-12 font-nunito--semibold"
                                format="$0,0.00"
                                :isTotalManual="true"
                                @onChange="changeRateAll(assessment.paintAdjRate)"
                        >
                        </number-formatter>
                    </ul>
                </li>
                <li v-show="['RR', 'Repair', 'Paint'].includes(selectedCard)" class="text-left font-12 font-nunito--semibold  btn btn-light btn-block">
                    Change Rate Line
                    <ul type="none" class="subContext">
                        <number-formatter
                                v-if="item && item.data && item.data.item"
                                v-model="item.data.item.adj_price"
                                type="text"
                                class="form-control numeric text-left font-12 font-nunito--semibold"
                                format="$0,0.00"
                                :isTotalManual="true"
                                @input="changeRateLine(item.data.item)"
                        >
                        </number-formatter>
                    </ul>
                </li>

                <li v-show="['Paint'].includes(selectedCard)" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
                    Change Paint Mtrl
                    <ul type="none" class="subContext paint-material d-flex btn-group-vertical">
                        <li @click="changePaintMaterial(item.data.item,'S1')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">S1</li>
                        <li @click="changePaintMaterial(item.data.item,'S2')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">S2</li>
                        <li @click="changePaintMaterial(item.data.item,'M1')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">M1</li>
                        <li @click="changePaintMaterial(item.data.item,'M2')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">M2</li>
                        <li @click="changePaintMaterial(item.data.item,'M3')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">M3</li>
                        <li @click="changePaintMaterial(item.data.item,'M3PLUS')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">M3+</li>
                    </ul>
                </li>
                <li v-show="['Paint'].includes(selectedCard)" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
                    Change Paint Mtrl All
                    <ul type="none" class="subContext paint-material d-flex btn-group-vertical">
                        <li @click="changePaintMaterialAll('S1')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">S1 to All</li>
                        <li @click="changePaintMaterialAll('S2')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">S2 to All</li>
                        <li @click="changePaintMaterialAll('M1')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">M1 to All</li>
                        <li @click="changePaintMaterialAll('M2')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">M2 to All</li>
                        <li @click="changePaintMaterialAll('M3')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">M3 to All</li>
                        <li @click="changePaintMaterialAll('M3PLUS')" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">M3+ to All</li>
                    </ul>
                </li>
                <li v-show="['Paint'].includes(selectedCard) && item && item.data && item.data.item && item.data.item.isAdjPaintTypeToAll" @click="undoChangePaintMaterialAll"
                    class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
                    Undo Paint Mtrl
                </li>
            </ul>
            <!-- bind :menu prop for part-name-menu -->


        </vue-context>

        <vue-context
            v-if="!isUserRoleLawyer"
            ref="add-new-line-context-menu"
            class="context-menu-right-click list-group"
            :closeOnClick="true"
            :closeOnScroll="true"
            :subMenuOffset="100">

          <ul slot-scope="item" ref="contextMenuForItem" class="d-flex btn-group-vertical">
            <li  @click="addNewLine" class="text-left font-12 font-nunito--semibold btn btn-light btn-block">
              Add Line
            </li>
          </ul>

        </vue-context>
    </div>
</template>
<script>
    /*eslint-disable */

    import ItemsTypeRR from './items-type-rr';
    import ItemsTypeRepair from './items-type-repair';
    import ItemsTypePaint from './items-type-paint';
    import ItemsTypePart from './items-type-part';
    import ItemsTypeMaterial from './items-type-material';
    import ItemsTypeSublet from './items-type-sublet';
    import ItemsTypeOther from './items-type-other';
    import ItemsTypePDR from './items-type-pdr';
    import NumberFormatter from '@/components/number-formatter';
    import {VueContext} from 'vue-context';
    import {mapGetters} from 'vuex';
    import accounting from 'accounting';
    import Axios from "axios";

    export default {
        name: 'assessment-items-list',
        props: ['itemslist', 'autoSaveItemPart', 'assessment', 'estimateId'],
        data: function () {
            return {
                selectedCard: 'RR',
                selectedItem: null,
                contextFocusIndex: 0,
                countOptions: 3, // might as well change to dynamic for later
                loading: false
            };
        },
        methods: {
            //...mapActions({
            //    changeItemPart: 'autoSave/changeItemPart',
            //}),
            openContextMenu(e) {
              this.$refs['add-new-line-context-menu'].open(e)
            },
            addNewLine() {
              this.addItemPart( { type: this.selectedCard } );
              this.$refs['add-new-line-context-menu'].close()
            },
            saveNewPart(data, index = false) {
                NProgress.start();
                let updateData = {
                    addManualItems: Object.assign({}, data)
                };
                return Axios({
                    method: 'post',
                    responseType: 'json',
                    headers: {'Autosave': true},
                    url: `/ir/assessment/${this.$route.params.assessmentId}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: updateData
                })
                    .then(response => {
                        if (response && response.status === 200 && response.data && response.data._status && response.data.newManualItems) {

                            for (let type in response.data.newManualItems) {
                                for (let item of response.data.newManualItems[type]) {
                                    if (index !== false) {
                                        this.itemslist[type].splice(index + 1, 0, item);
                                    } else {
                                        this.itemslist[type].push(item);
                                    }
                                }
                            }

                        } else {
                            this.$toast.error('Cannot save item');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            removeManualPart(data) {
                NProgress.start();
                let updateData = {
                    removeManualItems: Object.assign({}, data)
                };
                return Axios({
                    method: 'post',
                    responseType: 'json',
                    headers: {'Autosave': true},
                    url: `/ir/assessment/${this.$route.params.assessmentId}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: updateData
                })
                    .then(response => {
                        if (response && response.status === 200 && response.data && response.data._status && response.data.removedManualItems) {

                        } else {
                            this.$toast.error('Cannot save item');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            addItemPart(item, event) {
                console.log('addItemPart', item);
                console.log('addItemPartEvent', event);

                let part = {
                    qpsInfoId: this.estimateId,
                };
                if (item.index != undefined) {
                    part.index = item.index;
                }
                let toSend = {};
                toSend[item.type] = [part];
                this.saveNewPart(toSend, item.index);
            },
            removeItemPart(item) {
                console.log('removeItemPart', item);
                let toRemove = {};
                toRemove[item.type] = [item.id];
                this.removeManualPart(toRemove);
                let i = this.itemslist[item.type].indexOf(item);
                this.itemslist[item.type].splice(i, 1);
            },
            selectCard(c) {
                this.selectedCard = c;
            },
            changePaintMaterial(part, paintType, isAll = false, undoPaintType = false) {
                if (undoPaintType) {
                    let changePart = {
                        id: part.id,
                        undoPaintType: true,
                    };
                    this.autoSaveItemPart(changePart);
                    this.$set(part, 'isAdjPaintType', false);
                    this.$set(part, 'isAdjPaintTypeToAll', false);
                    part.paint_type = part.original_paint_type;
                } else {
                    let changePart = {
                        id: part.id,
                        type: part.type,
                        paint_type: paintType,
                        isAll: isAll,
                    };

                    this.autoSaveItemPart(changePart);
                    part.paint_type = paintType;
                    this.$set(part, 'isAdjPaintType', true);
                    if (isAll) {
                        this.$set(part, 'isAdjPaintTypeToAll', true);
                    }
                }
                this.handleContextMenuClose();
            },
            changePaintMaterialAll(paintType) {
                let vm = this;
                _.forEach(this.itemslist.Paint, function (item) {
                    vm.changePaintMaterial(item, paintType, true);
                });
                this.handleContextMenuClose();
            },
            undoChangePaintMaterialAll() {
                let vm = this;
                _.forEach(this.itemslist.Paint, function (item) {
                    vm.changePaintMaterial(item, '', false, true);
                });
                this.handleContextMenuClose();
            },
            changeItemPart(part) {
                console.log('changeItemPart', part);
                let changePart = {
                    type: part.type,
                    id: part.id,
                    comment: part.comment,
                    adj_hours: part.adj_hours,
                    adj_price: part.adj_price,
                    adj_qty: part.adj_qty,
                    adjMUP: part.adjMUP,
                    approveStatus: part.approveStatus,
                    reportText: part.reportText,
                };
                if (part.isManual) {
                    changePart.name = part.name;
                    changePart.hours = part.hours;
                    changePart.rate = part.rate;
                    changePart.price = part.price;
                    changePart.number = part.number;
                    changePart.qty = part.qty;
                }
                this.autoSaveItemPart(changePart);
            },
            // Context Menu - Methods
            handleContextMenuOpen() {
                return;
                // this.$refs['item-context-menu'].$el.querySelector('div[role="group"]').focus();
                //this.contextFocusIndex = 0;
                //this.$nextTick(() => {
                //    this.$refs['ctx-button-group'].setAttribute('tabindex', 0);
                //    this.$refs['ctx-button-group'].focus();
                //});
            },
            changeRateAll(q, w, e) {
                console.log('changeRateAll', q);

            },
            changeRateLine(q) {
                this.changeItemPart(q);
            },
            handleContextItemClick(action) {
                const list = this.itemslist[this.selectedCard];

                // change part in itemsList
                list.forEach(part => {
                    if (part.id === this.selectedItem.id) {
                        switch (action) {
                            case 'report': {
                                if (part.approveStatus !== 'reported') {
                                    if (part.approveStatus === 'denied') {
                                        part.comment = '';
                                        part.adj_hours = null;
                                        part.adj_qty = null;
                                        part.adj_price = null;
                                    }
                                    part.approveStatus = 'reported';
                                    part.reportText = part.reportText || '';
                                    if (['Part', 'Sublet'].includes(part.type)) {
                                        part.adj_price = 0;
                                        part.adjMUP = 0;
                                    } else if (['Materials'].includes(part.type)) {
                                        part.adj_price = 0;
                                        part.adj_hours = 0;
                                    } else if (['Other', 'PDR'].includes(part.type)) {
                                        part.adj_price = 0;
                                    } else {
                                        part.adj_hours = 0;
                                    }
                                } else {
                                    part.approveStatus = 'none';
                                    part.reportText = '';
                                    if (['Part', 'Sublet'].includes(part.type)) {
                                        part.adj_price = null;
                                        part.adjMUP = part.MUP;
                                    } else if (['Materials'].includes(part.type)) {
                                        part.adj_price = null;
                                        part.adj_hours = null;
                                    } else if (['Other', 'PDR'].includes(part.type)) {
                                        part.adj_price = null;
                                    } else {
                                        part.adj_hours = null;
                                    }
                                }
                                break;
                            }
                            case 'approve': {
                                if (part.approveStatus !== 'approved') {
                                    if (part.approveStatus === 'denied') {
                                        part.comment = '';
                                        part.adj_hours = null;
                                        part.adj_qty = null;
                                        part.adj_price = null;
                                    }
                                    part.approveStatus = 'approved';

                                } else {
                                    part.approveStatus = 'none';
                                }
                                break;
                            }
                            case 'decline': {
                                if (part.approveStatus !== 'denied') {
                                    part.approveStatus = 'denied';
                                    this.selectedItem.adj_hours = 0;
                                    this.selectedItem.adj_qty = 0;
                                    this.selectedItem.adj_price = 0;
                                } else {
                                    part.approveStatus = 'none';
                                    this.selectedItem.comment = '';
                                    part.comment = '';
                                    this.selectedItem.adj_hours = null;
                                    this.selectedItem.adj_qty = null;
                                    this.selectedItem.adj_price = null;
                                    part.adj_hours = null;
                                    part.adj_qty = null;
                                    part.adj_price = null;
                                }
                                break;
                            }
                            case 'changeRateLine': {
                                //part.adj_price = 150;
                                //console.log(part);
                                break;
                            }
                            case 'changeRateAll': {
                                //console.log(part);
                                //if (part.type == 'RR') {
                                //    this.assessment.rrAdjRate = 155;
                                //}
                                //if (part.type == 'Repair') {
                                //    this.assessment.repairAdjRate = 165;
                                //}
                                //if (part.type == 'Paint') {
                                //    this.assessment.paintAdjRate = 175;
                                //}
                                break;
                            }
                            default: {
                            }
                        }
                        // save to db
                        this.changeItemPart(part);
                    }
                });
            },
            handleRightClick(e, part) {
                if (this.isUserRoleLawyer) {
                    return;
                }
                let vm = this;
                this.$refs['item-context-menu'].open(e, {
                    item: part
                });
                this.selectedItem = part;
                console.log('part', part);
                setTimeout(() => {
                    [].slice
                        .call(this.$refs.contextMenuForItem.children)
                        .forEach((elem) => {
                            if (elem.children.length && elem.querySelector(".subContext")) {
                                elem.classList.add("hasSubContext");
                                elem.addEventListener("click", (e) => {
                                    e.stopPropagation();
                                });
                                elem.addEventListener("mouseenter", () => {
                                    if (
                                        window.innerWidth <
                                        elem.getBoundingClientRect().right +
                                        elem.querySelector(".subContext").getBoundingClientRect()
                                            .width
                                    ) {
                                        elem
                                            .querySelector(".subContext")
                                            .classList.add("position_sub_context_left");
                                    } else
                                        elem
                                            .querySelector(".subContext")
                                            .classList.remove("position_sub_context_left");
                                });
                            } else elem.classList.remove("hasSubContext");

                            //setTimeout(() => {
                            //    [].slice
                            //        .call(elem.children)
                            //        .forEach((elem2) => {
                            //            if (elem2.children.length && elem2.querySelector(".subContext2")) {
                            //                elem2.classList.add("hasSubContext2");
                            //                elem2.addEventListener("click", (e) => {
                            //                    e.stopPropagation();
                            //                });
                            //                elem2.addEventListener("mouseenter", () => {
                            //                    if (
                            //                        window.innerWidth <
                            //                        elem2.getBoundingClientRect().right +
                            //                        elem2.querySelector(".subContext2").getBoundingClientRect()
                            //                            .width
                            //                    ) {
                            //                        elem2
                            //                            .querySelector(".subContext2")
                            //                            .classList.add("position_sub_context_left");
                            //                    } else
                            //                        elem2
                            //                            .querySelector(".subContext2")
                            //                            .classList.remove("position_sub_context_left");
                            //                });
                            //            } else elem.classList.remove("hasSubContext2");
                            //        });
                            //}, 10);

                        });
                }, 0);

            },
            handleContextItemKeydown(e) {
                console.log('handleContextItemKeydown', e);
                e.preventDefault();
                e.stopPropagation();
                switch (e.keyCode) {
                    case 38: // up
                    {
                        this.contextFocusIndex--;
                        if (this.contextFocusIndex < 1) {
                            this.contextFocusIndex = this.countOptions;
                        }
                        this.$refs[`ctx-button-${this.contextFocusIndex}`].focus();
                        break;
                    }
                    case 9:  // tab
                    case 40: // down
                    {
                        // When tab clicked again inside menu
                        if (e.keyCode === 9 && this.contextFocusIndex !== 0) {
                            this.handleContextMenuClose();
                        }
                        this.contextFocusIndex++;
                        if (this.contextFocusIndex > this.countOptions) {
                            this.contextFocusIndex = 1;
                        }
                        this.$refs[`ctx-button-${this.contextFocusIndex}`].focus();
                        break;
                    }
                    case 32: // space
                    case 13: // enter
                    {
                        this.$refs[`ctx-button-${this.contextFocusIndex}`].click();
                        break;
                    }
                    case 8: // delete
                    case 27: // esc
                    default: {
                        this.handleContextMenuClose();
                    }
                }
            },
            handleContextMenuClose() {
                this.$refs['item-context-menu'].close();
                this.contextFocusIndex = 0;
                this.selectedItem = null;

                // turn button group unfocusable
                //this.$refs['ctx-button-group'].setAttribute('tabindex', -1);
            },
            // end Context Menu - Methods
            async saveItem(item) {
                return;
                this.loading = true;
                NProgress.start();
                try {
                    await this.changeItemPart(item);
                    this.$toast.success("Saved");
                    this.loading = false;
                    NProgress.done();
                } catch (e) {
                    console.log(e);
                    this.$toast.error("Not Saved");
                    this.loading = false;
                    NProgress.done();
                }
            },
            handleItemChange(item) {
                this.changeItemPart(item);
            }
        },
        components: {
            ItemsTypeRR,
            ItemsTypeRepair,
            ItemsTypePaint,
            ItemsTypePart,
            ItemsTypeMaterial,
            ItemsTypeSublet,
            ItemsTypeOther,
            ItemsTypePDR,
            NumberFormatter,
            VueContext
        },
        computed: {
            ...mapGetters({
                isUserRoleLawyer: 'currentUser/isRoleLawyer',
            }),
            computedSymbolForPercents() {
              if (this.adjSubTotal > this.subTotal) {
                return '+';
              }
              return '-';
            },
            computedRrAdjRate: {
                get() {
                    return this.assessment.rrAdjRate;
                },
                set: function (val) {
                    this.assessment.rrAdjRate = val;
                }
            },
            subTotal() {
                let subtotal = 0;
                _.forEach(this.itemslist, function (items, key) {
                    // console.log('key', key);
                    if (key == 'Materials' || key == 'PDR' || key == 'Other') {
                        _.forEach(items, function (item) {
                            subtotal += parseFloat(parseFloat(item.price || 0).toFixed(2));
                        });
                    } else if (key == 'Part') {
                        _.forEach(items, function (item) {
                            subtotal += parseFloat((parseFloat(item.qty || 0) * parseFloat(item.price || 0) * (1 + Number(item.MUP || 0) / 100)).toFixed(2));
                        });
                    } else if (key == 'Sublet') {
                        _.forEach(items, function (item) {
                            subtotal += parseFloat((parseFloat(item.price || 0) * (1 + Number(item.MUP || 0) / 100)).toFixed(2));
                        });
                    } else {
                        _.forEach(items, function (item) {
                            subtotal += parseFloat((parseFloat(item.hours || 0) * parseFloat(item.price || 0)).toFixed(2));
                        });
                    }
                });
                return parseFloat(subtotal.toFixed(2));
            },
            adjSubTotal() {
                let adjsubtotal = 0;
                _.forEach(this.itemslist, function (items, key) {
                    // console.log('key', key);
                    if (key == 'Materials' || key == 'PDR' || key == 'Other') {
                        _.forEach(items, function (item) {
                            if (item.approveStatus && (item.approveStatus === 'approved' || item.approveStatus === 'reported')) {
                                if (item.adj_price !== '' && item.adj_price != null) {
                                    adjsubtotal += parseFloat(parseFloat(item.adj_price).toFixed(2));
                                } else {
                                    adjsubtotal += parseFloat(parseFloat(item.price || 0).toFixed(2));
                                }
                            }
                        });
                    } else if (key == 'Part') {
                        _.forEach(items, function (item) {
                            if (item.approveStatus && (item.approveStatus === 'approved' || item.approveStatus === 'reported')) {
                                if (item.adj_price !== '' && item.adj_price != null) {
                                    adjsubtotal += parseFloat(parseFloat(item.adj_price).toFixed(2));
                                } else {
                                    adjsubtotal += parseFloat((parseFloat(item.qty || 0) * parseFloat(item.price || 0) * (1 + Number(item.adjMUP || 0) / 100)).toFixed(2));
                                }
                            }
                        });
                    } else if (key == 'Sublet') {
                        _.forEach(items, function (item) {
                            if (item.approveStatus && item.approveStatus === 'approved') {
                                if (item.adj_price !== '' && item.adj_price != null) {
                                    adjsubtotal += parseFloat(parseFloat(item.adj_price).toFixed(2));
                                } else {
                                    adjsubtotal += parseFloat(parseFloat((item.price || 0) * (1 + Number(item.adjMUP || 0) / 100)).toFixed(2));
                                }
                            }
                        });
                    } else {
                        _.forEach(items, function (item) {
                            if (item.approveStatus && (item.approveStatus === 'approved' || item.approveStatus === 'reported')) {
                                if (item.adj_price !== '' && item.adj_price != null && item.adj_hours !== '' && item.adj_hours != null) {
                                    adjsubtotal += parseFloat((parseFloat(item.adj_hours) * parseFloat(item.adj_price)).toFixed(2));
                                } else if (item.adj_price !== '' && item.adj_price != null) {
                                    adjsubtotal += parseFloat((parseFloat(item.hours || 0) * parseFloat(item.adj_price)).toFixed(2));
                                } else if (item.adj_hours !== '' && item.adj_hours != null) {
                                    adjsubtotal += parseFloat((parseFloat(item.adj_hours) * parseFloat(item.price || 0)).toFixed(2));
                                } else {
                                    adjsubtotal += parseFloat((parseFloat(item.hours || 0) * parseFloat(item.price || 0)).toFixed(2));
                                }
                            }
                        });
                    }
                });
                return parseFloat(adjsubtotal.toFixed(2));
            },
            isAdjusted() {
                return !!(this.subTotal === this.adjSubTotal);
            },
            adjSubtotalPercent() {
                if (this.adjSubTotal == 0 || this.subTotal == 0 || this.isAdjusted) {
                    return 0;
                }

                return accounting.toFixed(Math.abs(this.subTotal-this.adjSubTotal) / this.subTotal * 100, 2);
            },
        },
    };
</script>
<style scoped>

    .tabs-component-2 {
        top: 0px;
        /* background: rgb(36, 139, 229); */
    }

    .styled-adj {
        color: red !important;
    }

    .hasSubContext > ul > li {
        margin-right: 5px;
    }

    .hasSubContext:hover .subContext {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
    }

    .hasSubContext:hover .subContext.paint-material {
        transform: translateY(-185px);
    }

    .position_sub_context_left {
        right: calc(100% + 1px);
        left: auto;
    }

    .hasSubContext {
        position: relative;
    }

    .hasSubContext:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: calc(100% - 12px);
        width: 0;
        height: 0;
        border-color: transparent transparent transparent #5D6471;
        border-style: solid;
        border-width: 5px;
    }

    .subContext {
        position: absolute;
        left: calc(100% + 1px);
        background: #FFFFFF;
        border: 1px solid #BDBDBD;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
        min-width: 100px;
        top: 0;
        transition: 0.4s;
        visibility: hidden;
        opacity: 0;
        right: auto;
        transform: translateY(-10px);
    }

    .hasSubContext:hover .hasSubContext2:hover .subContext2 {
        opacity: 1;
        visibility: visible;
    }

    .hasSubContext:hover .hasSubContext2:hover .subContext2.paint-material-all {
        transform: translateY(-2px);
    }

    .hasSubContext .hasSubContext2 {
        position: relative;
    }

    .hasSubContext .hasSubContext2:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: calc(100% - 12px);
        width: 0;
        height: 0;
        border-color: transparent transparent transparent #5D6471;
        border-style: solid;
        border-width: 5px;
    }

    .subContext .subContext2 {
        position: absolute;
        left: calc(100% + 1px);
        background: #FFFFFF;
        border: 1px solid #BDBDBD;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
        min-width: 100px;
        top: 0;
        transition: 0.4s;
        visibility: hidden;
        opacity: 0;
        right: auto;
        transform: translateY(-10px);
    }

    @media screen and (max-width: 767px) {
        .hasSubContext:hover:after {
            transform: rotate(90deg);
        }

        .subContext {
            left: 0 !important;
            right: auto !important;
            top: 100% !important;
            width: 100% !important;
        }

        .hasSubContext2:hover:after {
            transform: rotate(90deg);
        }

        .subContext2 {
            left: 0 !important;
            right: auto !important;
            top: 100% !important;
            width: 100% !important;
        }
    }

</style>

<style>
    .section-for-empty-items-list {
      height: 650px;
    }

    tr.qtit td {
        position: relative;
    }

    tr.qtit:hover {
        background-color: #F9F9F9 !important;
        cursor: move;
    }

    tr.has-comment td {
        vertical-align: top !important;
    }

    tr.has-comment td:first-child input[type='text'] {
        margin-bottom: .75rem;
    }

    tr.qtit .comment {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: #5E6571;
        padding-left: 4px;
    }

    tr.qtit .comment span {
        display: inline-block;
        width: 60px;
        vertical-align: top;
    }

    tr.qtit .comment span:last-child {
        width: 72%;
    }

    .h-32 {
        height: 32px !important;
    }

    textarea.h-unset {
        height: unset !important;
    }

    .V3 .estimate-items-list .estimate-items span.form-control {
        height: 32px;
    }

    .V3 .col-form-label.nowrap {
        white-space: nowrap;
    }

    .V3 .nav-tabs__input-summary {
        width: 100% !important;
    }

    .theme-claimlinq #assessment-items-list-table label.checkbox > span.icon i {
        border: none;
        background-color: rgba(27, 30, 56, 0.03);
    }

    .v-context.context-menu-right-click.list-group {
        width: 140px;
    }

    .v-context.context-menu-right-click.list-group.list-group-paint {
        width: 180px;
    }

    .theme-claimlinq #assessment-items-list-table label.checkbox > span.icon.icon--success {
        background-color: #00B050 !important;
    }

    .theme-claimlinq #assessment-items-list-table label.checkbox > span.icon.icon--danger {
        background-color: #CE3939 !important;
    }

    .theme-claimlinq #assessment-items-list-table label.checkbox > span.icon.icon--warning {
        background-color: #FF7F2E !important;
    }

    .theme-claimlinq #assessment-items-list-table label.checkbox > span.icon i {
        color: transparent;
    }

    .theme-claimlinq #assessment-items-list-table label.checkbox > input[type="checkbox"]:checked + span.icon {
        background-color: #00B050;
    }

    .theme-claimlinq #assessment-items-list-table label.checkbox > input[type="checkbox"]:checked + span.icon i {
        color: #FFFFFF;
    }

    /* active row */
    .theme-claimlinq #assessment-items-list-table table tr.active {
        background-color: #F3F3F3;
    }

    .theme-claimlinq #assessment-items-list-table table tr.active input,
    .theme-claimlinq #assessment-items-list-table table tr.active .form-control,
    .theme-claimlinq #assessment-items-list-table table tr.active textarea {
        background-color: transparent !important;
    }

    /*
    ///////////////
     */


</style>
