<script>
    import {mapGetters} from 'vuex';
    export default {
        computed: {
            ...mapGetters({
                isUserRoleLawyer: 'currentUser/isRoleLawyer',
            }),
        },
        methods:{
            onKeyPressUp: function (fieldName, e, index) {
                if (e) {
                    e.preventDefault();
                }
                let maxParts = this.items.length;
                index -= 1;
                if (index < 0) {
                    index = maxParts - 1;
                }
                let id = fieldName + index;
                this.$nextTick(() => {
                    this.$refs[id][0].focus();
                });
            },
            onKeyPressDown: function (fieldName, e, index) {
                if (e) {
                    e.preventDefault();
                }
                let maxParts = this.items.length;
                index += 1;
                if (index >= maxParts) {
                    index = 0;
                }
                let id = fieldName + index;
                this.$nextTick(() => {
                    this.$refs[id][0].focus();
                });
            },
            onKeyPressLeft: function (fieldName, e, index, isEdit) {
                if (e && isEdit && isEdit == 'edit') {
                    var elem = e.srcElement;
                    let pos = this.getCaretPosition(elem);
                    if (pos > 0) {
                        return false;
                    }
                }
                if (e) {
                    e.preventDefault();
                }
                this.onKeyPressRightLeft(fieldName, index);
            },
            onKeyPressRight: function (fieldName, e, index, isEdit) {
                if (e && isEdit && isEdit == 'edit') {
                    var elem = e.srcElement;
                    let pos = this.getCaretPosition(elem);
                    if (pos < elem.value.length) {
                        return false;
                    }
                }
                if (e) {
                    e.preventDefault();
                }
                this.onKeyPressRightLeft(fieldName, index);
            },
            onKeyPressRightLeft: function (fieldName, index) {
                let id = fieldName + index;
                this.$nextTick(() => {
                    this.$refs[id][0].focus();
                });
            },
            getCaretPosition: function (ctrl) {
                // IE < 9 Support
                if (document.selection) {
                    ctrl.focus();
                    var range = document.selection.createRange();
                    var rangelen = range.text.length;
                    range.moveStart('character', -ctrl.value.length);
                    var start = range.text.length - rangelen;
                    return start;
                }
                // IE >=9 and other browsers
                else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
                    return ctrl.selectionEnd;
                } else {
                    return 0;
                }
            },
            setCaretPosition: function (ctrl, start) {
                // IE >= 9 and other browsers
                if (ctrl.setSelectionRange) {
                    ctrl.focus();
                    ctrl.setSelectionRange(start, start);
                }
                // IE < 9
                else if (ctrl.createTextRange) {
                    var range = ctrl.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', start);
                    range.moveStart('character', start);
                    range.select();
                }
            },
        }
    }

</script>
<style lang="scss" scoped>
    .form-control {
        &:focus {
            + .icon {
                box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
            }
        }
    }

</style>