<template>
    <div class="claimlinq-send-notice-to-repairer">
        <div class="page-header">
            <h4>Send Notice to Repairer</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Send Notice to Repairer</li>
            </ol>
        </div>
        <div class="page-body claimlinq-send-notice-to-repairer-body">
            <good-wizard
                    :steps="steps"
                    :currentStep="step"
                    ref="sendNoticeToRepairerStepper"
            >
                <div slot="send-notice-step-1">

                    <div class="subheader">
                        Review the details below for the Assessment you are about to send
                    </div>

                    <div class="claim-block bg-white">
                        <div class="form-group row">
                            <label class="col-sm-5 col-5 col-form-label">Assessment Number</label>
                            <div class="col-sm-7 col-7">
                                <input type="text" class="form-control" readonly :value="assessment.number" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-5 col-5 col-form-label">Vehicle Owner</label>
                            <div class="col-sm-7 col-7">
                                <input type="text" class="form-control" readonly :value="vehicle.owner"  />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-5 col-5 col-form-label">Rego</label>
                            <div class="col-sm-7 col-7">
                                <input type="text" class="form-control" readonly :value="vehicle.rego" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-5 col-5 col-form-label">Make</label>
                            <div class="col-sm-7 col-7">
                                <input type="text" class="form-control" readonly :value="vehicle.make" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-5 col-5 col-form-label">Model</label>
                            <div class="col-sm-7 col-7">
                                <input type="text" class="form-control" readonly :value="vehicle.model" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-5 col-5 col-form-label">Select Repairer</label>
                            <div class="col-sm-7 col-7">
                                <multiselect
                                    v-model="notice.repairer"
                                    @select="onRepairerSelected"
                                    :options="repairers"
                                    track-by="businessName"
                                    label="businessName"
                                    :showLabels="false"
                                    :option-height="29"
                                    :max-height="203"
                                    :close-on-select="true"
                                    placeholder="Select Repairer"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-5 col-5 col-form-label">Email Address <span class="indicate">*</span></label>
                            <div class="col-sm-7 col-7">
                                <input type="text" class="form-control" placeholder="Email Address" v-model="notice.repairerEmail" :disabled="!enableEditRepairerEmail">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="mobile" class="col-sm-5 col-5 col-form-label">
                                Sent with Guest Access
                            </label>
                            <div class="col-sm-7 col-7 ex-owner-type-container">
                                <label class="form-check-inline checkbox">
                                    <input class="form-check-input" name="guestAccess" v-model="notice.guestAccess" type="checkbox" disabled>
                                    <span class="icon" :class="{'icon-bg-grey': notice.repairer.id && !notice.guestAccess}"><i class='bx bx-check' ></i></span>
                                    <span v-if="notice.repairer.id && !notice.guestAccess" class="text indicate font-12">This repairer is not set up with Guest Access</span>
                                </label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="mobile" class="col-sm-5 col-5 col-form-label">
                                Generate a Unique Link <br>
                                <span v-if="expiryDate && !isExpired" class="small">Open for 7 Days Expires {{ expiryDateFormatted }}</span>
                            </label>
                            <div class="col-sm-7 col-7 ex-owner-type-container">
                                <label class="form-check-inline checkbox">
                                    <input class="form-check-input" name="uniqueLink" v-model="notice.uniqueLink" type="checkbox" disabled>
                                    <span class="icon" :class="{'icon-bg-grey': notice.repairer.id && !enableUniqueLink}"><i class='bx bx-check'></i></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div slot="send-notice-step-2">
                    <div class="subheader">
                        Set the required information to be added to the assessment
                    </div>
                    <div>
                        <div class="form-group row">
                            <label class="col-sm-5 col-5 col-form-label">Assessor</label>
                            <div class="col-sm-7 col-7">
                                <input type="text" class="form-control" readonly :value="assessment.manual.assessorName || assessment.assessorName" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="mobile" class="col-sm-5 col-5 col-form-label">
                                Set Date Vehicle In
                            </label>
                            <div class="col-sm-7 col-7 ex-owner-type-container">
                                <label class="form-check-inline checkbox">
                                    <input class="form-check-input" name="guestAccess" v-model="notice.requirements.setDateVehicleIn" type="checkbox">
                                    <span class="icon"><i class='bx bx-check'></i></span>
                                </label>
                            </div>
                        </div>
                        <br>

                        <template v-for="requirement of requirementsForm">
                            <div :key="requirement.id">
                                <div class="form-group row" >
                                    <label for="mobile" class="col-sm-5 col-5 col-form-label">
                                        {{ requirement.label }}
                                    </label>
                                    <div class="col-sm-7 col-7 ex-owner-type-container">
                                        <label class="form-check-inline checkbox">
                                            <input class="form-check-input" name="guestAccess" type="checkbox" @change="toggleAllRequirements(requirement.id)">
                                            <span class="icon"><i class='bx bx-check'></i></span>
                                            <span class="text font-12">All</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group row" v-for="form of requirement.forms" :key="form.id">
                                    <label for="mobile" class="col-sm-5 col-5 col-form-label">
                                        {{ form.label }}
                                    </label>
                                    <div class="col-sm-7 col-7 ex-owner-type-container">
                                        <label class="form-check-inline checkbox">
                                            <input class="form-check-input" name="guestAccess" v-model="notice.requirements[requirement.id][form.id]" type="checkbox">
                                            <span class="icon"><i class='bx bx-check'></i></span>
                                        </label>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </template>

                    </div>
                </div>
            </good-wizard>
            <div class="wizard__buttons">

                <div>&nbsp;</div>
                <button v-if="$refs.sendNoticeToRepairerStepper && $refs.sendNoticeToRepairerStepper.currentStep == 0"
                        class="btn btn-outline-primary button-cancel"
                        type="button"
                        @click.prevent="clickCancel">
                    Cancel
                </button>
                <button v-if="$refs.sendNoticeToRepairerStepper && $refs.sendNoticeToRepairerStepper.currentStep != 0"
                        class="btn btn-outline-primary button-back"
                        type="button"
                        @click.prevent="$refs.sendNoticeToRepairerStepper.goBack()">
                    Back
                </button>
                <button v-if="$refs.sendNoticeToRepairerStepper && $refs.sendNoticeToRepairerStepper.currentStep != 1"
                        class="btn btn-primary button-next"
                        type="button"
                        @click.prevent="$refs.sendNoticeToRepairerStepper.goNext()"
                        :tabindex="4">
                    Next
                </button>
                <button v-if="$refs.sendNoticeToRepairerStepper && $refs.sendNoticeToRepairerStepper.currentStep == 1"
                        class="btn btn-primary button-claim"
                        type="button"
                        @click.prevent="sendNotice"
                        :disabled="loading"
                >
                    Send
                </button>
            </div>
        </div>
    </div>
</template>

<script>
/*eslint-disable */
import GoodWizard from '@/components/vue-good-wizard';
import Axios from "axios";
import Multiselect from 'vue-multiselect';
import moment from 'moment';

export default {
    name: 'send-notice-to-repairer',
    components: {
        GoodWizard,
        Multiselect
    },
    data() {
        return {
            loading: false,
            step: 2,
            steps: [
                {
                    label: 'Assessment Check',
                    slot: 'send-notice-step-1',
                },
                {
                    label: 'Set Requirements',
                    slot: 'send-notice-step-2',
                }
            ],
            assessment: {
                id: '',
                number: '',
                manual: {}
            },
            repairers: [],
            vehicle: {
                rego: '',
                make: '',
                model: '',
                owner: ''
            },
            // this property will be send as payload
            notice: {
                _status: true,
                repairerId: '',
                repairer: {
                    createdOn: null
                },
                repairerEmail: '',
                guestAccess: false,
                uniqueLink: false,
                requirements: {
                    setDateVehicleIn: false,
                    vehicle: {},
                    damageReporting: {}
                }
            },
            // select states tab 1
            enableEditRepairerEmail: true,
            enableUniqueLink: true,

            // tab 2 forms
            requirementsForm: {
                vehicle: {
                    id: 'vehicle',
                    label: 'Vehicle',
                    checkAll: false,
                    forms: [
                        {
                            label: 'Body',
                            id: 'body',
                        },
                        {
                            label: 'Date of Manufacturer',
                            id: 'dateManufacture'
                        },
                        {
                            label: 'VIN',
                            id: 'vin'
                        },
                        {
                            label: 'Paint Code',
                            id: 'paintCode'
                        },
                        {
                            label: 'Odometer',
                            id: 'odometer'
                        },
                        {
                            label: 'Transmission',
                            id: 'transmission'
                        }
                    ]
                },
                damageReporting: {
                    id: 'damageReporting',
                    label: 'Damage Reporting',
                    checkAll: false,
                    forms: [
                        {
                            label: 'View existing images',
                            id: 'viewExistingImages'
                        },
                        {
                            label: 'Upload new images',
                            id: 'uploadNewImages'
                        },
                        {
                            label: 'View existing files',
                            id: 'viewExistingFiles',
                        },
                        {
                            label: 'Upload new files',
                            id: 'uploadNewFiles'
                        },
                        {
                            label: 'Comment on Damages',
                            id: 'commentOnDamages'
                        }
                    ]
                }

            }
        }
    },
    computed: {
        expiryDate() {
            if (this.notice.repairer.id) {
                return new Date(new Date(this.notice.repairer.createdOn).getTime() + (7 * 24 * 60 * 60 * 1000))
            } else {
                return null
            }
        },
        isExpired() {
            if (!this.expiryDate) {
                return true
            }
            const today = new Date().getTime();
            return new Date(this.expiryDate).getTime() < today;
        },
        expiryDateFormatted() {
            return moment(this.expiryDate).format('DD-MMM-YYYY')
        }
    },
    async mounted() {
        // load data
        try {
            // add loading state
            const assessmentId = this.$route.params.assessmentId;
            const { data } = await Axios.get(`/ir/assessment/${assessmentId}/send-notice-to-repairer`);
            this.assessment = data.assessment;
            this.repairers = data.repairers;
            this.vehicle = data.vehicle;
        } catch (error) {
            console.log({
                message: 'Error when getting assessment data',
                error
            });
        } finally {
            // stop loading state
        }


      this.notice.uniqueLink = true;
      this.enableUniqueLink = true;

    },
    methods: {
        clickCancel: function () {
            this.$router.push({name: 'Claims'});
        },
        // callback after selecting repairer
        onRepairerSelected(repairer) {

            // if email isEmailPrimary, use the email and;
            // email cannot be edited
            // otherwise leave it blank to be inputted manually
            if (repairer.isEmailPrimary) {
                this.notice.repairerEmail = repairer.email;
                // this.enableEditRepairerEmail = false;
            } else {
                this.notice.repairerEmail = '';
            }

            // update guest access
            // also enable/disabled behavior
            this.notice.guestAccess = repairer.guestAccess;

            // repairer expiryDate

            this.notice.uniqueLink = true;
            this.enableUniqueLink = true;

        },
        toggleAllRequirements(id) {
            // change master toggle
            this.requirementsForm[id].checkAll = !this.requirementsForm[id].checkAll

            // change requirements base on master
            this.requirementsForm[id].forms.forEach(requirement => {
                this.$set(this.notice.requirements[id], requirement.id, this.requirementsForm[id].checkAll)
            })
        },
        async sendNotice() {
            try {
                this.loading = true;
                const assessment_id = this.$route.params.assessmentId;

                const dataToSend = {
                  repairerId: this.notice.repairer.id,
                  repairerEmail: this.notice.repairerEmail,
                  dateVehicleIn: this.notice.requirements.setDateVehicleIn,
                  vehicleBody: this.notice.requirements.vehicle.body,
                  vehicleDom: this.notice.requirements.vehicle.dateManufacture,
                  vehicleVin: this.notice.requirements.vehicle.vin,
                  vehiclePaintCode: this.notice.requirements.vehicle.paintCode,
                  vehicleOdometer: this.notice.requirements.vehicle.odometer,
                  vehicleTransmission: this.notice.requirements.vehicle.transmission,
                  viewExistingImages: this.notice.requirements.damageReporting.viewExistingImages,
                  viewUploadImages: this.notice.requirements.damageReporting.uploadNewImages,
                  viewExistingFiles: this.notice.requirements.damageReporting.viewExistingFiles,
                  viewUploadFiles: this.notice.requirements.damageReporting.uploadNewFiles,
                  commentOnDamages: this.notice.requirements.damageReporting.commentOnDamages,
                };

                const data = await Axios.post(`/ir/assessment/${assessment_id}/send-notice-to-repairer`, dataToSend);
                console.log(data);

                if(data.data && data.data._status){
                  this.$toast.success('Notice to repairer has been sent!')
                  await this.$router.push(`/assessments/${this.assessment.id}`)
                } else {
                  this.$toast.error('Error! Something went wrong!')
                }

            } catch (error) {
              this.$toast.error('Error! Something went wrong!')
                console.log({
                    message: 'Failed when sending notice to repairer',
                    error
                })
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.form-control[readonly=readonly] {
    border-color: transparent;
}
.form-control[readonly=readonly]:focus {
    box-shadow: none;
}

/* grey select */
.theme-claimlinq label.checkbox > span.icon.icon-bg-grey {
    background-color: #1B1E381A;
}
.theme-claimlinq label.checkbox > span.icon.icon-bg-grey .bx {
    color: transparent;
}


</style>

<style>
    .V3 .new-claim {
        max-width: 1028px;
        margin: 0 auto;
    }
</style>
<style lang="scss" scoped>
    .claimlinq-send-notice-to-repairer-body ::v-deep .wizard__steps {
        width: 900px;
    }

</style>
<style scoped>

    .V3 .claimlinq-send-notice-to-repairer-body > .claim-block {
        padding: 0px;
    }

    .V3 .claimlinq-send-notice-to-repairer-body .claim-block {
        padding: 0px;
    }

    .V3 .wizard__buttons {
        display: flex;
        position: relative;
        flex-flow: row wrap;
        justify-content: flex-end;
        max-width: 573px;
        margin: 0 auto;
    }

    .V3 .wizard__buttons .btn {
        flex: 0 1 120px;
        margin-left: 15px;
        margin-bottom: 15px;
        min-width: 82px;
        padding: 10px;
    }

    .V3 .wizard__buttons .btn:first-child {
        margin-right: auto;
        margin-left: 0px;
    }

    .V3 .wizard__buttons .btn:last-child {
        margin-right: 0;
    }

    .V3 .wizard__buttons .button-cancel {
        border-color: rgba(28, 31, 57, 0.25);
        color: #1C1F39;
    }

    .V3 .wizard__buttons .button-cancel:hover,
    .V3 .wizard__buttons .button-cancel:focus {
        background-color: rgba(28, 31, 57, 0.62);
        color: white;
    }

    @media screen and (max-width: 625px) {
        .V3 .wizard__buttons {
            padding: 0 15px;
            width: 100%;
        }

        .V3 .wizard__buttons .btn {
            flex-grow: 1;
            font-size: 13px;
            font-weight: bold;
            line-height: 18px;
            height: 39px;
        }

        .V3 .wizard__buttons .button-claim-estimate {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100%;
            margin-left: 0px;
        }
    }

    @media screen  and (max-width: 320px) {
        .V3 .wizard__buttons .btn:nth-child(3) {
            margin-left: 0px;
        }
    }
</style>

