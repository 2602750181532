<template>
    <div class="claimlinq-assessment-new">
        <!--        eslint-disable-->
        <div class="page-header">
            <h4>Create New Assessment</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/assessments">Assessments</router-link>
                </li>
                <li class="breadcrumb-item active">New Assessment</li>
            </ol>
        </div>
        <div class="claimlinq-assessment-new-body">
            <good-wizard
                    :steps="steps"
                    :currentStep="step"
                    ref="assessmentNewStepper"
            >
                <div slot="assessment-new-step-1" class="assessment-new-step-1">
                    <div class="header">
                        Assessment Details
                    </div>
                    <div class="subheader">
                    </div>
                    <div class="assessment-new assessment-new-check">

                        <div class="claim-block bg-white">
                            <div class="form-group row">
                                <label class="col-md-5 col-form-label">Specify the Assessment Type</label>
                                <div class="col-md-7">
                                    <label class="form-check-inline checkbox">
                                        <input class="form-check-input" type="checkbox" checked="checked" disabled readonly :value="true">
                                        <span class="icon"><i class='bx bx-check'></i></span>
                                        <span class="text">Motor Vehicle Repairs</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-form-label">Assessment is being performed on behalf of</label>
                                <div class="col-sm-3">
                                    <label class="form-check-inline radio">
                                        <input class="form-check-input" v-model="claimType" type="radio" :value="'insured'">
                                        <span class="icon"><i class='bx bx-check'></i></span>
                                        <span class="text">Insured</span>
                                    </label>
                                </div>
                                <div class="col-sm-4">
                                    <label class="form-check-inline radio">
                                        <input class="form-check-input" v-model="claimType" type="radio" :value="'otherParty'">
                                        <span class="icon"><i class='bx bx-check'></i></span>
                                        <span class="text">Other Party</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-form-label">Select Estimate Type</label>
                                <div class="col-sm-3">
                                    <label class="form-check-inline radio">
                                        <input class="form-check-input" v-model="estimateType" type="radio" :value="'estimate'">
                                        <span class="icon"><i class='bx bx-check'></i></span>
                                        <span class="text">Main Estimate</span>
                                    </label>
                                </div>
                                <div class="col-sm-4">
                                    <label class="form-check-inline radio">
                                        <input class="form-check-input" v-model="estimateType" type="radio" :value="'supplement'">
                                        <span class="icon"><i class='bx bx-check'></i></span>
                                        <span class="text">Supplement Estimate</span>
                                    </label>
                                </div>
                            </div>

                            <div class="form-group row" :class="estimateType">
                                <label class="col-sm-5 col-form-label">{{getUploadMessage}}</label>
                                <div class="col-sm-7" style="padding-top: calc(0.375rem + 1px);padding-bottom: calc(0.375rem + 1px);">
                                    <b-form-file
                                            v-model="pdfFile"
                                            accept="application/pdf"
                                            placeholder="Choose PDF"
                                    ></b-form-file>
                                </div>
                            </div>

                            <div class="form-group row" :class="estimateType">
                                <label class="col-sm-5 col-form-label">Upload Status</label>
                                <div class="col-sm-7">
                                    {{computedUploadStatus}}
                                </div>
                            </div>
                            <div v-if="estimateType == 'supplement'" class="form-group row">
                                <label class="col-md-5 col-form-label">Link this supplement to an existing Assessment</label>
                                <div class="col-md-7">
                                    <multiselect
                                            v-model="computedLinkedAssessment"
                                            track-by="id"
                                            label="number"
                                            :showLabels="true"
                                            :selectLabel="'Press enter to link'"
                                            :selectedLabel="'Liked'"
                                            :options="assessments"
                                            :option-height="29"
                                            :max-height="203"
                                            :close-on-select="true"
                                            placeholder="Select Assessment"
                                            @select="onLinkedAssessmentSelect"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 col-form-label">Vehicle Owner Name</label>
                                <div class="col-md-7">
                                    <b-form-input v-model="info.vehicleOwnerName"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 col-form-label">Rego</label>
                                <div class="col-md-7">
                                    <b-form-input v-model="info.vehicleRego"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 col-form-label">Make</label>
                                <div class="col-md-7">
                                    <b-form-input v-model="info.vehicleMake"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 col-form-label">Model</label>
                                <div class="col-md-7">
                                    <b-form-input v-model="info.vehicleModel"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 col-form-label">VIN Number</label>
                                <div class="col-md-7">
                                    <b-form-input v-model="info.vehicleVIN" readonly></b-form-input>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 col-form-label">Repairer Name</label>
                                <div class="col-md-7">
                                    <b-form-input v-model="info.repairerName" readonly></b-form-input>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 col-form-label">Estimate Number</label>
                                <div class="col-md-7">
                                    <b-form-input v-model="info.estimateNumber" readonly></b-form-input>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-form-label">Is the information above correct?</label>
                                <div class="col-sm-2">
                                    <label class="form-check-inline radio">
                                        <input class="form-check-input" v-model="isCorrectData" type="radio" :value="true">
                                        <span class="icon"><i class='bx bx-check'></i></span>
                                        <span class="text">Yes</span>
                                    </label>
                                </div>
                                <div class="col-sm-4">
                                    <label class="form-check-inline radio">
                                        <input class="form-check-input" v-model="isCorrectData" type="radio" :value="false">
                                        <span class="icon"><i class='bx bx-check'></i></span>
                                        <span class="text">No</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div slot="assessment-new-step-2">
                    <div class="header">
                        Review — {{showEstimateType}} {{showReferenceNumber?' — '+ showReferenceNumber:''}}
                    </div>
                    <div class="subheader">
                    </div>
                    <div v-if="estimateType != 'later' && pdfData && pdfData.isConverted" class="assessment-new invoice">
                        <table v-if="(pdfData.itemsList.RR && pdfData.itemsList.RR.items && pdfData.itemsList.RR.items.length >0) || (pdfData.itemsList.Repair && pdfData.itemsList.Repair.items && pdfData.itemsList.Repair.items.length >0) || (pdfData.itemsList.Paint && pdfData.itemsList.Paint.items && pdfData.itemsList.Paint.items.length >0)"
                               class="table b-table main-table m0">
                            <thead>
                            <tr>
                                <th>
                                    Repairs
                                </th>
                            </tr>
                            </thead>
                        </table>
                        <table v-if="pdfData.itemsList.RR && pdfData.itemsList.RR.items && pdfData.itemsList.RR.items.length >0 " class="table b-table table-preview main-table">
                            <thead>
                            <tr class="nobrd">
                                <th class="preview-name">
                                    R&R
                                </th>
                                <th class="preview-hours">
                                    Hours
                                </th>
                                <th class="preview-rate">
                                    Rate
                                </th>
                                <th class="preview-amount">
                                    Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(item, ii) in pdfData.itemsList.RR.items">
                                <tr class="nobrd">
                                    <td class="preview-name">
                                        {{ item.name }}
                                    </td>
                                    <td class="preview-hours">
                                        {{ item.hours }}
                                    </td>
                                    <td class="preview-rate">
                                        {{parseFloat(parseFloat(item.hours>0?item.amount/item.hours:0).toFixed(2)) | formatMoney}}
                                    </td>
                                    <td class="preview-amount">
                                        {{ item.amount | formatMoney }}
                                    </td>
                                </tr>
                            </template>
                            <tr class="nobrd">
                                <td class="subtotal-hours">
                                    Subtotal Hrs
                                </td>
                                <td class="subtotal-hours-value">
                                    {{ pdfData.itemsList.RR.subtotalHours }}
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr class="nobrd">
                                <td colspan="3" class="subtotal">
                                    Subtotal
                                </td>
                                <td class="subtotal-value">
                                    {{ pdfData.itemsList.RR.subtotal | formatMoney }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table v-if="pdfData.itemsList.Repair && pdfData.itemsList.Repair.items && pdfData.itemsList.Repair.items.length >0 " class="table b-table table-preview main-table">
                            <thead>
                            <tr class="nobrd">
                                <th class="preview-name">
                                    Repair
                                </th>
                                <th class="preview-hours">
                                    Hours
                                </th>
                                <th class="preview-rate">
                                    Rate
                                </th>
                                <th class="preview-amount">
                                    Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(item, ii) in pdfData.itemsList.Repair.items">
                                <tr class="nobrd">
                                    <td class="preview-name">
                                        {{ item.name }}
                                    </td>
                                    <td class="preview-hours">
                                        {{ item.hours }}
                                    </td>
                                    <td class="preview-rate">{{parseFloat(parseFloat(item.hours>0?item.amount/item.hours:0).toFixed(2)) | formatMoney}}</td>
                                    <td class="preview-amount">
                                        {{ item.amount | formatMoney }}
                                    </td>
                                </tr>
                            </template>
                            <tr class="nobrd">
                                <td class="subtotal-hours">
                                    Subtotal Hrs
                                </td>
                                <td class="subtotal-hours-value">
                                    {{ pdfData.itemsList.Repair.subtotalHours }}
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr class="nobrd">
                                <td colspan="3" class="subtotal">
                                    Subtotal
                                </td>
                                <td class="subtotal-value">
                                    {{ pdfData.itemsList.Repair.subtotal | formatMoney }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table v-if="pdfData.itemsList.Paint && pdfData.itemsList.Paint.items && pdfData.itemsList.Paint.items.length >0 " class="table b-table table-preview main-table">
                            <thead>
                            <tr class="nobrd">
                                <th class="preview-name">
                                    Paint
                                </th>
                                <th class="preview-hours">
                                    Hours
                                </th>
                                <th class="preview-rate">
                                    Rate
                                </th>
                                <th class="preview-amount">
                                    Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(item, ii) in pdfData.itemsList.Paint.items">
                                <tr class="nobrd">
                                    <td class="preview-name">
                                        {{ item.name }}
                                    </td>
                                    <td class="preview-hours">
                                        {{ item.hours }}
                                    </td>
                                    <td class="preview-rate">{{parseFloat(parseFloat(item.hours>0?item.amount/item.hours:0).toFixed(2)) | formatMoney}}</td>
                                    <td class="preview-amount">
                                        {{ item.amount | formatMoney }}
                                    </td>
                                </tr>
                            </template>
                            <tr class="nobrd">
                                <td class="subtotal-hours">
                                    Subtotal Hrs
                                </td>
                                <td class="subtotal-hours-value">
                                    {{ pdfData.itemsList.Paint.subtotalHours }}
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr class="nobrd">
                                <td colspan="3" class="subtotal">
                                    Subtotal
                                </td>
                                <td class="subtotal-value">
                                    {{ pdfData.itemsList.Paint.subtotal | formatMoney }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table v-if="pdfData.itemsList.Part && pdfData.itemsList.Part.items && pdfData.itemsList.Part.items.length >0 " class="table b-table table-preview main-table">
                            <thead>
                            <tr class="nobrd">
                                <th class="preview-name">
                                    Part
                                </th>
                                <th class="preview-partnumber" style="text-align: center;">
                                    Number
                                </th>
                                <th class="preview-hours">
                                    Type
                                </th>
                                <th class="preview-rate">
                                    Qty
                                </th>
                                <th class="preview-amount">
                                    Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(item, ii) in pdfData.itemsList.Part.items">
                                <tr class="nobrd">
                                    <td class="preview-name">
                                        {{ item.name }}
                                    </td>
                                    <td class="preview-partnumber">
                                        {{ item.partnumber }}
                                    </td>
                                    <td class="preview-rate">{{ item.supply }}</td>
                                    <td class="preview-rate">{{ item.qty }}</td>
                                    <td class="preview-amount">
                                        {{ item.amount | formatMoney }}
                                    </td>
                                </tr>
                            </template>

                            <tr class="nobrd">
                                <td colspan="3" class="subtotal">
                                    Subtotal
                                </td>
                                <td colspan="2" class="subtotal-value">
                                    {{ pdfData.itemsList.Part.subtotal | formatMoney }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table v-if="pdfData.itemsList.Other && pdfData.itemsList.Other.items && pdfData.itemsList.Other.items.length >0 " class="table b-table table-preview main-table">
                            <thead>
                            <tr class="nobrd">
                                <th class="preview-name">
                                    Other
                                </th>
                                <th class="preview-amount">
                                    Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(item, ii) in pdfData.itemsList.Other.items">
                                <tr class="nobrd">
                                    <td class="preview-name">
                                        {{ item.name }}
                                    </td>
                                    <td class="preview-amount">
                                        {{ item.amount | formatMoney }}
                                    </td>
                                </tr>
                            </template>
                            <tr class="nobrd">
                                <td class="subtotal">
                                    Subtotal
                                </td>
                                <td class="subtotal-value">
                                    {{ pdfData.itemsList.Other.subtotal | formatMoney }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table b-table table-preview main-table">
                            <tbody>
                            <tr>
                                <td class="total-total">Total</td>
                                <td class="total-total-value">{{computedTotal | formatMoney}}</td>
                            </tr>
                            <tr>
                                <td class="total-gst">GST</td>
                                <td class="total-gst-value">{{computedGST| formatMoney}}</td>
                            </tr>
                            <tr>
                                <td class="total-grand-total">Total Inc GST</td>
                                <td class="total-grand-total-value">{{computedGrandTotal| formatMoney}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else class="assessment-new invoice">
                        <h4 class="text-center">
                            <br>
                            No PDF data imported
                            <br>
                            <br>
                            <br>
                        </h4>

                    </div>

                </div>

                <div slot="assessment-new-step-3">
                    <div class="header">
                        Select Images
                    </div>

                    <div class="subheader">
                        Select images to upload and add to the estimate
                    </div>
                    <div class="assessment-new assessment-new-files">
                        <div class="box box-block">
                            <div class="row">
                                <table class="table b-table select-parts">
                                    <thead class="bg-navyblue">
                                    <tr>
                                        <th class="header-check">
                                            <label class="form-check-inline checkbox">

                                            </label>
                                        </th>
                                        <th class="header-name" style="text-align: left;"></th>
                                        <th class="header-name"></th>
                                    </tr>
                                    </thead>
                                </table>

                                <file-upload
                                        class="file-upload-new-assessment"
                                        type="image"
                                        :objectId="0"
                                        :value="[]"
                                        :config="{maxFilesize: 100, thumbnailWidth: 200, timeout: 300000, addRemoveLinks: true, resizeWidth: 1500, resizeQuality: 0.7, acceptedFiles: 'image/jpeg, image/jpg, image/png', }"
                                        :api-urls="uploadUrls"
                                        :files-accepted="'image/png, image/jpeg'"
                                        :dynamic-width="true"
                                        :after-upload-function-override="afterUploadImage"
                                        :after-upload="afterUploadImageEmitted"
                                >
                                </file-upload>
                            </div>
                        </div>
                    </div>

                    <div class="subheader">
                        Choose the images to submit by ticking the box next to the image.
                    </div>
                    <div class="assessment-new assessment-new-images">
                        <image-selector :images="images" :path="''" :props-selected-images="selectedImages" @onChange="onImageSelect"></image-selector>
                    </div>

                </div>

                <div slot="assessment-new-step-4">
                    <div class="header">
                        Upload Files
                    </div>
                    <div class="subheader">
                        Select files to upload and add to the estimate
                    </div>

                    <div class="assessment-new assessment-new-files">
                        <div class="box box-block">
                            <div class="row">
                                <table class="table b-table select-parts">
                                    <thead class="bg-navyblue">
                                    <tr>
                                        <th class="header-check">
                                            <label class="form-check-inline checkbox">

                                            </label>
                                        </th>
                                        <th class="header-name" style="text-align: left;"></th>
                                        <th class="header-name"></th>
                                    </tr>
                                    </thead>
                                </table>

                                <file-upload
                                        type="file"
                                        class="file-upload-new-assessment"
                                        :api-urls="uploadUrls"
                                        :files-accepted="'application/pdf, image/jpeg'"
                                        :value="[]"
                                        :object-id="0"
                                        :dynamic-width="false"
                                        :after-upload-function-override="afterUploadFile"
                                        :after-upload="afterUploadFileEmitted"
                                >
                                </file-upload>

                            </div>
                        </div>
                    </div>
                    <div class="subheader">
                        Tick the Files to be added as shown below
                    </div>
                    <div class="assessment-new assessment-new-files">
                        <div class="box box-block">
                            <div class="row">
                                <table class="table b-table select-parts">
                                    <thead class="bg-navyblue">
                                    <tr>
                                        <th colspan="3" class="header-check">
                                            <label class="form-check-inline checkbox">
                                                <input @click="toggleSelectAllFiles" class="form-check-input" type="checkbox" v-model="allFilesSelected" :checked="allFilesSelected">
                                                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Select All</span>
                                            </label>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="file in files" v-bind:key="file.id">
                                        <td class="file-checkbox">
                                            <label class="form-check-inline checkbox">
                                                <input class="form-check-input" type="checkbox" v-model="file.selected">
                                                <span class="icon"><i class='bx bx-check'></i></span>
                                            </label>
                                        </td>
                                        <td class="file-name">{{ file.name }}</td>
                                        <td class="file-status">
                                            <strong>Status:</strong>
                                            <span v-show="!file.selected" class="text-muted">Not attached</span>
                                            <strong v-show="file.selected">To be attached</strong>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

            </good-wizard>
            <div class="wizard__buttons">
                <button v-if="$refs.assessmentNewStepper && $refs.assessmentNewStepper.currentStep != 0"
                        class="btn btn-outline-primary button-back"
                        type="button"
                        @click="$refs.assessmentNewStepper.goBack()">
                    Back
                </button>
                <button v-if="$refs.assessmentNewStepper && $refs.assessmentNewStepper.currentStep == 0"
                        class="btn btn-outline-primary button-cancel"
                        type="button"
                        @click="clickCancel">
                    Cancel
                </button>
                <button v-if="$refs.assessmentNewStepper && $refs.assessmentNewStepper.currentStep != 0"
                        class="btn btn-outline-primary button-cancel"
                        type="button"
                        @click="clickCancel">
                    Cancel
                </button>
                <button v-if="$refs.assessmentNewStepper && $refs.assessmentNewStepper.currentStep != 3"
                        class="btn btn-primary button-next"
                        type="button"
                        @click="$refs.assessmentNewStepper.goNext()"
                        :tabindex="4"
                        :disabled="!isAssessmentDetailsStepCorrect"
                >
                    Next
                </button>
                <button v-if="$refs.assessmentNewStepper && $refs.assessmentNewStepper.currentStep == 3"
                        class="btn btn-primary button-claim"
                        type="button"
                        @click="clickSend"
                        :disabled="isSending"
                >
                    Create
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    /*eslint-disable */

    import Axios from 'axios';
    import {mapGetters} from 'vuex';
    import NumberFormatter from '@/components/number-formatter';
    import GoodWizard from '@/components/vue-good-wizard';
    import Multiselect from 'vue-multiselect';
    import ImageSelector from "@/components/image-selector";
    import FileUpload from '@/components/FileUpload';

    export default {
        name: "assessment-new-for-assessment-company",
        data: function () {
            return {
                selectedAssessment: {
                    id: null,
                    number: '',
                },
                allFilesSelected: false,
                images: [],
                selectedImages: [],
                files: [],
                steps: [
                    {
                        label: 'Assessment Details',
                        slot: 'assessment-new-step-1',
                    },
                    {
                        label: 'Review',
                        slot: 'assessment-new-step-2',
                        options: {},
                    },
                    {
                        label: 'Images',
                        slot: 'assessment-new-step-3',
                        options: {},
                    },
                    {
                        label: 'Files',
                        slot: 'assessment-new-step-4'
                    }
                ],
                step: 1,
                isSending: false,
                pdfFile: null,
                pdfInsertFileId: null,
                pdfData: {
                    isConverted: null,
                    business: {},
                    estimateNumber: '',
                    estimateBy: '',
                    itemsList: {
                        RR: {
                            items: [],
                            type: '',
                            subtotal: 0,
                            subtotalHours: 0,
                        },
                        Repair: {
                            items: [],
                            type: '',
                            subtotal: 0,
                            subtotalHours: 0,
                        },
                        Paint: {
                            items: [],
                            type: '',
                            subtotal: 0,
                            subtotalHours: 0,
                        },
                        Material: {
                            items: [],
                            type: '',
                            subtotal: 0,
                            subtotalHours: 0,
                        },
                        Part: {
                            items: [],
                            type: '',
                            subtotal: 0,
                            subtotalHours: 0,
                        },
                        Sublet: {
                            items: [],
                            type: '',
                            subtotal: 0,
                            subtotalHours: 0,
                        },
                        Other: {
                            items: [],
                            type: '',
                            subtotal: 0,
                            subtotalHours: 0,
                        },
                        PDR: {
                            items: [],
                            type: '',
                            subtotal: 0,
                            subtotalHours: 0,
                        }
                    }
                },
                info: {
                    vehicleOwnerName: '',
                    vehicleRego: '',
                    vehicleVIN: '',
                    vehicleModel: '',
                    vehicleMake: '',
                    estimateNumber: '',
                    repairerName: '',
                    linkedAssessment: '',
                },
                assessments: [],
                claimType: null,
                isCorrectData: null,
                estimateType: null,
            };
        },
        methods: {
            onLinkedAssessmentSelect(item) {
            },
            afterUploadImage(file, data) {
                if (data && file && data._status && data.fileId > 0) {
                    this.images.push({
                        url: file.dataURL,
                        src: file.dataURL,
                        id: data.fileId,
                    });
                } else {
                    //this.$toast.error('Error');
                }
                //this.$toast.success('Image uploaded');
            },
            afterUploadImageEmitted() {
            },
            afterUploadFile(file, data) {
                if (data && file && data._status && data.fileId > 0) {
                    this.files.push({
                        name: file.name,
                        selected: false,
                        id: data.fileId,
                    });
                }
                //this.$toast.success('Image uploaded');
            },
            afterUploadFileEmitted() {
            },
            toggleSelectAllFiles() {
                if (this.allFilesSelected) {
                    this.files.forEach(file => file.selected = false);
                } else {
                    this.files.forEach(file => file.selected = true);
                }
            },
            onImageSelect: function (imgs) {
                this.selectedImages = imgs;
            },
            clickCancel: function () {
                this.$router.push('/assessments');
            },
            clickSend: function () {
                NProgress.start();
                let url = '/ir/assessment/create';
                this.isSending = true;
                let files = [];
                files.push(this.pdfInsertFileId);
                this.files.forEach(f => {
                    if (!f.selected) return;
                    files.push(f.id);
                });
                let data = {
                    files,
                    images: this.selectedImages,
                    type: 2,
                    claimType: this.claimType,
                    estimateType: this.estimateType,
                    itemsList: this.pdfData.itemsList,
                    estimator: this.pdfData.estimateBy,
                    estimateNumber: this.pdfData.estimateNumber,
                    info: this.info,
                    selectedAssessmentId: (this.selectedAssessment && this.selectedAssessment.id) ? this.selectedAssessment.id : null,
                };
                Axios.post(url, data)
                    .then(response => {
                        if (response.data && response.data._status) {
                            this.$router.push(`/assessments/${response.data.assessmentId}`);
                        } else {
                            this.$toast.error('Something went wrong!');
                        }
                    })
                    .catch(err => {
                        this.$toast.error(err);
                    })
                    .finally(() => {
                        NProgress.done();
                        this.isSending = false;
                    });
            },
            loadSetup: function () {
                NProgress.start();
                Axios.get(`/ir/assessment/for-new-assessment`)
                    .then((response) => {
                        if (response.data && response.data._status) {
                            this.assessments = response.data.assessments;
                            console.log(response.data);
                        }
                    })
                    .catch((error) => {
                        console.log('error:', error);
                    })
                    .finally(() => NProgress.done());
            },
            parsePdf(newVal) {
                NProgress.start();
                var formData = new FormData();
                formData.append("image", newVal);
                Axios.post('/ir/assessment/parse-estimate-pdf', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    console.log('PDF', response);
                    if (response && response.data && response.data._status) {
                        this.pdfData = response.data.data;
                        if (Number(this.pdfData.pdfFileId) > 0) {
                            this.pdfInsertFileId = this.pdfData.pdfFileId;
                        }
                        this.info.vehicleVIN = this.pdfData.vin;
                        this.info.vehicleRego = this.pdfData.rego;
                        this.info.vehicleOwnerName = this.pdfData.owner;
                        this.info.vehicleMake = this.pdfData.make || this.pdfData.vehicle;
                        this.info.vehicleModel = this.pdfData.model || this.pdfData.vehicle;
                        this.info.estimateNumber = this.pdfData.estimateNumber;
                        this.info.repairerName = this.pdfData.business?.name;
                        this.$toast.success('PDF has been imported successfully! You can check imported data on the "Review" tab');
                    } else {
                        this.pdfData = {};
                        this.pdfData.isConverted = false;
                        this.$toast.error('Cannot imported PDF. Choose another file, please');
                    }
                }).catch(e => {
                    console.error(e);
                    this.pdfData = {};
                    this.pdfData.isConverted = false;
                })
                    .finally(() => NProgress.done());
            },
        },
        computed: {
            ...mapGetters({
                isAssessments: 'currentCompany/isAssessments',
            }),
            computedLinkedAssessment: {
                get() {
                    return this.selectedAssessment;
                },
                set(newVal) {
                    this.selectedAssessment = newVal;
                }
            },
            isAssessmentDetailsStepCorrect() {
                if (!this.isCorrectData) {
                    return false;
                }
                if (!this.pdfData || this.pdfData.isConverted !== true) {
                    return false;
                }
                if (!this.estimateType || !(this.estimateType == 'estimate' || this.estimateType == 'supplement')) {
                    return false;
                }
                return true;
            },
            getUploadMessage() {
                if (this.estimateType == 'estimate') {
                    return 'Upload Main Estimate PDF';
                } else if (this.estimateType == 'supplement') {
                    return 'Upload Supplement Estimate PDF';
                } else {
                    return 'Upload PDF';
                }
            },
            computedTotal() {
                let total = 0;
                if (this.pdfData && this.pdfData.isConverted && this.pdfData.itemsList) {
                    if (this.pdfData.itemsList.RR && this.pdfData.itemsList.RR.subtotal) {
                        total += parseFloat(this.pdfData.itemsList.RR.subtotal);
                    }
                    if (this.pdfData.itemsList.Repair && this.pdfData.itemsList.Repair.subtotal) {
                        total += parseFloat(this.pdfData.itemsList.Repair.subtotal);
                    }
                    if (this.pdfData.itemsList.Paint && this.pdfData.itemsList.Paint.subtotal) {
                        total += parseFloat(this.pdfData.itemsList.Paint.subtotal);
                    }
                    if (this.pdfData.itemsList.Other && this.pdfData.itemsList.Other.subtotal) {
                        total += parseFloat(this.pdfData.itemsList.Other.subtotal);
                    }
                    if (this.pdfData.itemsList.Part && this.pdfData.itemsList.Part.subtotal) {
                        total += parseFloat(this.pdfData.itemsList.Part.subtotal);
                    }
                }
                return total;
            },
            computedGST() {
                let gst = this.computedTotal * 0.1;
                return parseFloat(parseFloat(gst).toFixed(2));
            },
            computedGrandTotal() {
                let total = this.computedTotal + this.computedGST;
                return parseFloat(parseFloat(total).toFixed(2));
            },
            uploadUrls() {
                return {uploadFile: `/ir/file/upload`};
            },
            showEstimateType() {
                if (this.estimateType == 'supplement') {
                    return 'Supplement';
                }
                return 'Estimate';
            },
            showReferenceNumber() {
                if (this.pdfData && this.pdfData.estimateNumber && this.pdfData.estimateNumber != '') {
                    return this.pdfData.estimateNumber;
                }
                return null;
            },
            computedUploadStatus() {
                if (this.pdfData && this.pdfData.isConverted === true) {
                    return 'Success';
                } else if (this.pdfData && this.pdfData.isConverted === false) {
                    return 'Upload Failed';
                }
                return '';
            },
            isPdfLoaded() {
                return false;
            },
            ifUpdatedReport() {
                return !!(this.report.status == 'updated');
            },
            urls() {
                return {uploadFile: `/ir/claim/${this.claim}/add-file`};
            },
        },
        mounted: function () {
            this.loadSetup();
        },
        watch: {
            pdfFile(newVal) {
                console.log(newVal);
                this.parsePdf(newVal);
            },
        },
        components: {
            NumberFormatter,
            GoodWizard,
            Multiselect,
            ImageSelector,
            FileUpload,
        }
    };
</script>

<style>
    .V3 .new-claim {
        max-width: 1028px;
        margin: 0 auto;
    }

    /*
    Preview table
     */

    .V3 .preview table {
        width: 100%;
    }

    .V3 .preview .table-preview td,
    .V3 .preview .table-preview th {
        width: 10%;
    }

    .V3 .preview .table-preview td,
    .V3 .preview .table-preview tr,
    .V3 .preview .table-preview th {
        margin: 0px;
        padding: 0px;
        vertical-align: top;
    }

    .V3 .preview div,
    .V3 .preview div span {
        color: #1C1F39;
        font-size: 12px;
        line-height: 16px;
        font-weight: normal;
    }

    .V3 .preview .table-preview > tr > td {
        border-bottom: 2px solid rgba(27, 30, 56, 0.1);
        border-top: none;
    }

    .V3 .preview .table-preview table.nobrd > tr > td,
    .V3 .preview .table-preview .nobrd > td,
    .V3 .preview .table-preview .nobrd > th {
        border: none !important;
    }

    .V3 .preview .preview-header .preview-header-title {
        font-size: 18px;
        font-weight: 800;
        letter-spacing: 2px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .V3 .preview .preview-header .preview-header-business-name {
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
    }

    .V3 .preview .preview-header .preview-header-item .preview-header-item-name {
        font-weight: 700;
    }

    .V3 .preview .preview-header .preview-header-item .preview-header-item-value {

    }

    .V3 .preview .preview-header .preview-header-delimiter {
        height: 20px;
    }

    .V3 .preview .preview-header {
        padding-bottom: 30px !important;
        padding-left: 36px;
    }

    .V3 .preview .preview-head-name {
        height: 36px;
        border-radius: 3px;
        background-color: rgba(27, 30, 56, 0.1);
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
        padding: 9px 15px 8px 15px;
        border-bottom: 1px solid #FFFFFF;
    }

    .V3 .preview .preview-subhead-name {
        height: 36px;
        border-radius: 3px;
        background-color: rgba(27, 30, 56, 0.1);
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        padding: 9px 15px 14px 15px;
        border-bottom: 1px solid #FFFFFF;
    }

    .V3 .preview .preview-item-name {
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        padding: 13px 15px 4px 15px;
    }

    .V3 .preview .preview-item-value {
        font-size: 12px;
        line-height: 16px;
        padding: 13px 15px 4px 15px;
    }

    .V3 .preview .preview-align-right {
        text-align: right;
    }

    .V3 .preview .preview-amount-header {
        text-align: right;
        padding-right: 50px;
    }

    .V3 .preview .preview-amount {
        text-align: right;
        padding-right: 60px;
    }


    .V3 .preview .preview-delimiter {
        height: 20px;
    }

    .V3 .preview .w-33 {
        width: 33% !important;
    }

    .V3 .preview .w-25 {
        width: 25% !important;
    }

    .V3 .preview .preview-total-plus {
        color: #000000;
        position: absolute;
        top: -20px;
        left: 20px;
    }

    .V3 .preview .preview-terms-delimeter {
        box-sizing: border-box;
        height: 1px;
        width: 100%;
        border-top: 1px solid rgba(27, 30, 56, 0.25);
        margin-top: 40px;
        margin-bottom: 25px;
    }

    .V3 .preview .preview-terms-header {
        height: 19px;
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
        padding-bottom: 15px;
    }

    .V3 .preview .preview-terms {
        padding-right: 47px;
    }

    .V3 .preview div,
    .V3 .preview div span {
        color: #1C1F39;
        font-size: 12px;
        line-height: 16px;
        font-weight: normal;
    }

    .V3 .preview .table-preview > tr > td {
        border-bottom: 2px solid rgba(27, 30, 56, 0.1);
        border-top: none;
    }

    .V3 .preview .table-preview table.nobrd > tr > td,
    .V3 .preview .table-preview .nobrd > td,
    .V3 .preview .table-preview .nobrd > th {
        border: none !important;
    }

    .V3 .preview .preview-header .preview-header-title {
        font-size: 18px;
        font-weight: 800;
        letter-spacing: 2px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .V3 .preview .preview-header .preview-header-business-name {
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
    }

    .V3 .preview .preview-header .preview-header-item .preview-header-item-name {
        font-weight: 700;
    }

    .V3 .preview .preview-header .preview-header-item .preview-header-item-value {

    }

    .V3 .preview .preview-header .preview-header-delimiter {
        height: 20px;
    }

    .V3 .preview .preview-header {
        padding-bottom: 30px !important;
        padding-left: 36px;
    }

    #address input {
        border: 0;
        height: 30px;
    }

    .V3 .preview .preview-head-name {
        height: 36px;
        border-radius: 3px;
        background-color: rgba(27, 30, 56, 0.1);
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
        padding: 9px 15px 8px 15px;
        border-bottom: 1px solid #FFFFFF;
    }

    .V3 .preview .preview-subhead-name {
        height: 36px;
        border-radius: 3px;
        background-color: rgba(27, 30, 56, 0.1);
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        padding: 9px 15px 14px 15px;
        border-bottom: 1px solid #FFFFFF;
    }

    .V3 .preview .preview-item-name {
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        padding: 13px 15px 4px 15px;
    }

    .V3 .preview .preview-item-value {
        font-size: 12px;
        line-height: 16px;
        padding: 13px 15px 4px 15px;
    }

    .V3 .preview .preview-align-right {
        text-align: right;
    }

    .V3 .preview .preview-amount-header {
        text-align: right;
        padding-right: 50px;
    }

    .V3 .preview .preview-amount {
        text-align: right;
        padding-right: 60px;
    }


    .V3 .preview .preview-delimiter {
        height: 20px;
    }

    .V3 .preview .w-33 {
        width: 33% !important;
    }

    .V3 .preview .w-25 {
        width: 25% !important;
    }

    .V3 .preview .preview-total-plus {
        color: #000000;
        position: absolute;
        top: -20px;
        left: 20px;
    }

    .V3 .preview .preview-terms-delimeter {
        box-sizing: border-box;
        height: 1px;
        width: 100%;
        border-top: 1px solid rgba(27, 30, 56, 0.25);
        margin-top: 40px;
        margin-bottom: 25px;
    }

    .V3 .preview .preview-terms-header {
        height: 19px;
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
        padding-bottom: 15px;
    }

    .V3 .preview .preview-terms {
        padding-right: 47px;
    }

    .V3 .notf-icon {
        width: 20px;
        height: 16px;
        position: relative;
        display: inline-block;
    }

    .V3 .notf-icon .tooltip-text {
        visibility: hidden;
        width: 200px;
        background-color: white;
        color: black;
        border: 1px solid #5E79FF;
        border-radius: 3px;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: bold;
        position: absolute;
        z-index: 1;
        text-align: center;
        padding: 5px;
        margin-left: -94px;
    }

    .V3 .notf-icon .tooltip-text.pos-top {
        bottom: 150%;
    }

    .V3 .notf-icon .tooltip-text.pos-top:after {
        content: ' ';
        position: absolute;
        top: 100%;
        left: 50%;
        marrgin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #5E79FF transparent transparent transparent;
    }

    .V3 .notf-icon .tooltip-text.pos-bottom {
        top: 150%;
    }

    .V3 .notf-icon .tooltip-text.pos-bottom:after {
        content: ' ';
        position: absolute;
        bottom: 100%;
        left: 50%;
        marrgin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #5E79FF transparent;
    }

    .V3 .notf-icon:hover .tooltip-text {
        visibility: visible;
    }

    #address input {
        border: 0;
        height: 30px;
    }

    /********Table View***************/
    .font-weight-600 {
        font-weight: 600;
    }

    .table-view .tabs-nav-right-btn {
        margin-left: 0;
    }

    .add-button-table i {
        font-weight: bolder !important;
    }

    .table-view .search-input {
        box-sizing: border-box;
        height: 38px;
        width: 191px;
        border: 1px solid #1C1F39;
        border-radius: 3px;
    }

    .table-view .tabs-nav-right-search {
        min-width: 195px !important;
    }

    .table-view .add-button-table {
        height: 38px;
        position: relative;
        bottom: 1px;
        border-radius: 3px;
        background-color: #5E79FF;
        color: #FFFFFF;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        padding-left: 7px;

    }

    .table-view .td-text {
        color: #1C1F39;
        font-family: "Nunito Sans";
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
    }

    .table-view .th-text {
        color: #1C1F39;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }


    .settings-page-users .user-avatar-section {
        padding-left: 0 !important;
    }

    .settings-page-users .user-avatar-section button {
        width: 100%;
        border-radius: 3px;
        color: #FFFFFF;
        font-size: 13px;
        font-weight: bold;
        background-color: #FF5E5E !important;
        margin-top: 10px;
        height: 30px;
    }

</style>
<style lang="scss" scoped>

    .claimlinq-assessment-new .claimlinq-assessment-new-body ::v-deep .file-upload-new-assessment {
        width: 100%;
        text-align: center;
    }

    .form-group.row.later label {
        opacity: 0.7;
    }

    .form-group.row.later div {
        opacity: 0.7;
    }

    .wizard .wizard__body .assessment-new-files table.b-table .file-checkbox {
        width: 35px;
        min-width: 35px;
        padding-right: 0px;
    }

    .wizard .wizard__body .assessment-new-files table.b-table .file-checkbox label {
        margin-right: 0px;
    }

    .wizard .wizard__body .assessment-new-files table.b-table .file-name {
        width: 99%;
        padding-left: 0px;
    }

    .wizard .wizard__body .assessment-new-files table.b-table .file-status {
        width: 250px;
        min-width: 250px;
    }

    .claimlinq-assessment-new-body ::v-deep .wizard__steps {
        width: 900px;

        /* prevent too wide element */
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        /*overflow-x: scroll;*/

        /* remove vertical scroll */
        overflow-y: hidden;
    }

    .claimlinq-assessment-new-body ::v-deep .wizard__body {
        width: 900px;

        /* prevent too big table */
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        margin-bottom: 15px;
        /*overflow-x: scroll;*/

        /* remove unecessary scroll */
        min-width: unset;
    }

    .claimlinq-assessment-new-body ::v-deep .wizard__body .assessment-new-step-1 {
        width: 573px;
        margin: 0px auto 0 auto;

        /* prevent unecessary scroll */
        max-width: 100%;
    }

    .claimlinq-assessment-new-body ::v-deep .wizard__body.vgw-mobile {
        width: 100% !important;
    }

    @media screen and (max-width: 730px) {
        .claimlinq-assessment-new-body ::v-deep .wizard__body .assessment-new-step-1 {
            width: 98vw;
            margin: 0;
        }
    }

    .wizard .wizard__body table.b-table tr th,
    .wizard .wizard__body table.b-table tr td {
        /*padding: 13px 15px;*/
        padding-left: 15px;
        padding-right: 15px;
    }

    .wizard .wizard__body table.b-table tr th {
        background-color: rgba(27, 30, 56, 0.07) !important;
    }

    .grey-color {
        opacity: 0.6;
    }

    .claimlinq-assessment-new-body .assessment-new .header-check {
        /*padding: 0.75rem;*/
        width: 35px;
        min-width: 35px;
    }

    .claimlinq-assessment-new-body .assessment-new .header-name {
        line-height: 35px;
    }

    .V3 .claimlinq-assessment-new-body > .claim-block {
        padding: 0px;
    }

    .V3 .claimlinq-assessment-new-body .claim-block {
        padding: 0px;
    }

    .V3 .wizard__buttons {
        display: flex;
        position: relative;
        flex-flow: row wrap;
        justify-content: flex-end;
        max-width: 573px;
        margin: 0 auto;
    }

    .V3 .wizard__buttons .btn {
        flex: 0 1 120px;
        margin-left: 15px;
        margin-bottom: 15px;
        min-width: 82px;
        padding: 10px;
    }

    .V3 .wizard__buttons .btn:first-child {
        margin-right: auto;
        margin-left: 0px;
    }

    .V3 .wizard__buttons .btn:last-child {
        margin-right: 0;
    }

    .V3 .wizard__buttons .button-cancel {
        border-color: rgba(28, 31, 57, 0.25);
        color: #1C1F39;
    }

    .V3 .wizard__buttons .button-cancel:hover,
    .V3 .wizard__buttons .button-cancel:focus {
        background-color: rgba(28, 31, 57, 0.62);
        color: white;
    }

    @media screen and (max-width: 625px) {
        .V3 .wizard__buttons {
            padding: 0 15px;
            width: 100%;
        }

        .V3 .wizard__buttons .btn {
            flex-grow: 1;
            font-size: 13px;
            font-weight: bold;
            line-height: 18px;
            height: 39px;
        }

        .V3 .wizard__buttons .button-claim-estimate {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100%;
            margin-left: 0px;
        }
    }

    @media screen and (max-width: 320px) {
        .V3 .wizard__buttons .btn:nth-child(3) {
            margin-left: 0px;
        }
    }

    .table.table-preview th, .table.table-preview td {
        padding: 0;
        height: 25px;
    }

    .block-uploader {
        width: 100%;
    }

    /* HEADER TABLE */
    .invoice-info {
        padding-right: 5px;

        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;

        margin-bottom: 10px;
    }

    .invoice-title {
        color: #1C1F39;
        font-family: "Nunito Sans";
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 24px;
        margin: 0 0 20px;
    }

    .invoice .header-table {
        margin: 0 0 20px
    }

    .invoice .header-table td {
        color: #1C1F39;
    }

    /* CONTENT TABLE */
    .m0 {
        margin: 0 !important;
    }

    .invoice .main-table thead th,
    .invoice .main-table tbody th {
        background: #F2F2F2;
        height: 35px !important;
    }

    .invoice .main-table td, .invoice .main-table th {
        text-align: left;
        border-bottom: 1px solid white;

    }

    .invoice .main-table thead th {
        padding: 8px 15px;
        font-size: 13px;
        font-weight: 700;
    }

    .invoice .main-table tbody th, .invoice .main-table tfoot th {
        padding: 8px 15px;
        color: #3F3F3F;
        font-size: 12px;
        font-weight: 700;
    }

    .invoice .main-table thead th {
        border-radius: 3px;
    }

    .invoice .main-table tbody th:first-child {
        border-radius: 3px 0 0 3px;
    }

    .invoice .main-table tbody th:last-child {
        border-radius: 0 3px 3px 0;
    }

    .invoice .main-table .preview-name {
        width: 99%;
        text-align: left;
        font-weight: 700;
    }

    .invoice .main-table .preview-hours {
        width: 70px;
        min-width: 70px;
        text-align: center;
    }

    .invoice .main-table .preview-partnumber {
        width: 140px;
        min-width: 140px;
        text-align: left;
    }

    .invoice .main-table .preview-rate {
        width: 100px;
        min-width: 100px;
        text-align: center;
    }

    .invoice .main-table .preview-amount {
        width: 100px;
        min-width: 100px;
        text-align: right;
    }

    .invoice .main-table .subtotal-hours {
        text-align: right;
        font-weight: 700;
    }

    .invoice .main-table .subtotal-hours-value {
        text-align: center;
    }

    .invoice .main-table .subtotal {
        text-align: right;
        font-weight: 700;
    }

    .invoice .main-table .subtotal-value {
        text-align: right;
    }

    .invoice .main-table .total-total {
        width: 99%;
        font-weight: 700;
        text-align: right;
    }

    .invoice .main-table .total-total-value {
        width: 100px;
        min-width: 100px;
        text-align: right;
    }

    .invoice .main-table .total-gst {
        width: 99%;
        font-weight: 700;
        text-align: right;
    }

    .invoice .main-table .total-gst-value {
        width: 100px;
        min-width: 100px;
        text-align: right;
    }

    .invoice .main-table .total-grand-total {
        width: 99%;
        font-weight: 700;
        text-align: right;
        font-size: 13px;
    }

    .invoice .main-table .total-grand-total-value {
        width: 100px;
        min-width: 100px;
        text-align: right;
        font-size: 13px;
    }

    /* Owner details */
    .invoice .main-table .vehicle-details td,
    .invoice .main-table .main-inner td {
        padding: 5px 0;
        border: none;
        color: #1C1F39;
    }

    .invoice .main-table .main-inner td:first-child {
        min-width: 110px;
        width: 40%;
        font-weight: 700;
    }

    .invoice .main-table tbody td,
    .invoice .main-table tfoot td {
        padding: 5px 15px;
        color: #1C1F39;
        font-size: 12px;
        /* font-weight: 400; */
    }

    .invoice .main-table tbody tr:first-child td,
    .invoice .main-table tfoot td:first-child td {
        /* padding-top: 5px; */
    }

    .invoice .repairs-total tbody td {
        padding: 5px 15px;
        border: none;
    }

    .invoice .main-total-table {
        margin-bottom: 20px;
        width: auto !important;
        float: right;
    }

    .invoice .main-total-table tr:last-child td {
        border-bottom: 0
    }

    .latest_amount_table td.border-bottom,
    .invoice td.border-bottom {
        position: relative;
        border-bottom: 1px solid rgba(27, 30, 56, 0.25);
    }

    .latest_amount_table td:last-child.border-bottom::after,
    .invoice td:last-child.border-bottom::after {
        content: "+";
        position: absolute;
        font-size: 22px;
        bottom: -17px;
        right: -5px;
    }

    .latest_amount_table td:last-child.border-bottom::after {
        bottom: -8px;
    }

    .invoice-view .main-table tfoot td.subtotal-right {
        text-align: right;
        padding-right: 25px;
        font-weight: 700;
    }

</style>
