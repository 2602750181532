<template>

    <div class="row">
        <div class="col-12 col-lg-10 col-xl-8">
            <block-activity
                    ref="block-activity"
                    :activity="activity"
                    @loadActivity="loadActivity"
                    @loadImages="loadImages"
                    @loadFiles="loadFiles"
            />
        </div>
    </div>

</template>

<script>

    import BlockActivity from '@/components/blocks/common/block-activity';
    import Axios from "axios";

    export default {
        name: "activity-tab",
        components: {
            BlockActivity,
        },
        props: {
            assessment: {
                type: [Object, null],
                default() {
                    return null;
                }
            }
        },
        data() {
            return {
                activity: [],
            };
        },
        methods: {
            loadImages() {
                this.$emit('loadImages');
            },
            loadFiles() {
                this.$emit('loadFiles');
            },
            loadActivity(timestamp = null) {
                //if(!this.assessment) return;
                let aid = this.$route.params.assessmentId;
                if (!aid) {
                    return;
                }
                let url = `/ir/activity/get-for-assessment/${aid}`;
                if (timestamp > 0) {
                    url += "/" + timestamp;
                }
                Axios.get(url)
                    .then(response => {
                        if (response.data && response.data._status) {
                            this.$store.dispatch('updater/resetActivity');
                            if (timestamp === null) {
                                this.activity = response.data.data;
                            } else {
                                let vm = this;
                                let additional = [];
                                _.forEach(response.data.data, function (na) {
                                    let el = _.find(vm.activity, function (a) {
                                        return Number(a.id) === Number(na.id);
                                    });
                                    if (el) {
                                        console.log('exist element', el);
                                        el = na;
                                    } else {
                                        console.log('additaonal element', na);
                                        additional.push(na);
                                    }
                                });
                                _.forEachRight(additional, function (el) {
                                        vm.activity.unshift(el);
                                    }
                                );

                            }
                        }
                    })
                    .catch(e => console.error(e));
            },
            showNewTaskForm() {
                this.$refs['block-activity'].showNewTaskForm();
            }
        },
        mounted() {
            this.loadActivity();
        },
        watch: {
            assessment() {
                //this.loadActivity();
                setTimeout(this.loadActivity, 3000);
            }
        }
    };
</script>

<style scoped>

</style>
