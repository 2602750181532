<script>
    /*eslint-disable */
    import Axios from 'axios';
    import {mapGetters} from 'vuex';
    import NumberFormatter from '@/components/number-formatter';
    import GoodWizard from '@/components/vue-good-wizard';
    import Multiselect from 'vue-multiselect';
    import ImageSelector from "@/components/image-selector";

    export default {
        name: 'assessment-print-report',
        data: function () {
            return {
                allFilesSelected: false,
                allEstimateFilesSelected: false,
                images:[],
                selectedImages: [],
                files:[],
                pdfs: {
                  original: null,
                  adjusted: null,
                },
                pdfsInfo: {
                  original: {
                    selected: false,
                    messageAttached: 'Original estimate to be attached',
                    messageNotAttached: 'Original estimate not attached',
                  },
                  adjusted: {
                    selected: false,
                    messageAttached: 'Adjusted estimate to be attached',
                    messageNotAttached: 'Adjusted estimate not attached',
                  },
                },
                assessment: {},
                assessmentId: 0,
                estimateId: 0,
                report: {
                    assessmentNumber: '',
                    date: '',
                    status: 'new',
                    statusDateCreate: '',
                    hasInvoice: false,
                },
                steps: [
                    {
                        label: 'Assessment Check',
                        slot: 'assessment-print-report-step-1',
                    },
                    {
                        label: 'Select Images',
                        slot: 'assessment-print-report-step-2',
                        options: {},
                    },
                    {
                        label: 'Select Files',
                        slot: 'assessment-print-report-step-3'
                    }
                ],
                step: 1,
                isSending: false,
                numberToUse: null,
            };
        },
        methods: {
            toggleSelectAllFiles() {
                if (this.allFilesSelected) {
                    this.files.forEach(file => file.selected = false);
                } else {
                    this.files.forEach(file => file.selected = true);
                }
            },
            toggleSelectAllEstimateFiles() {
                if (this.allEstimateFilesSelected) {
                    this.pdfsInfo.original.selected = false;
                    this.pdfsInfo.adjusted.selected = false;
                } else {
                    this.pdfsInfo.original.selected = true;
                    this.pdfsInfo.adjusted.selected = true;
                }
            },
            onImageSelect: function (imgs) {
                this.selectedImages = imgs;
            },
            clickCancel: function () {
                this.$router.push('/assessments/' + this.assessment.id);
            },
            clickSend: function () {
                NProgress.start();
                this.isSending = true;
                this.isCreatingClaim = true;
                let files = [];
                this.files.forEach(f => {
                    if(!f.selected) return;
                    files.push(f.id);
                });
                let data = {
                  files,
                    images: this.selectedImages,
                  original: this.pdfsInfo.original.selected,
                  adjusted: this.pdfsInfo.adjusted.selected,
                    invoice: this.report.invoice,
                };
                Axios.post(
                      this.estimateId
                          ? `/ir/assessment/download-report-pdf-for-supplement/${this.estimateId}`
                          : `/ir/assessment/download-report-pdf/${this.assessmentId}`
                    , data)
                    .then(response => {
                        if (response.data && response.data._status){
                          let a = document.createElement('a');
                          a.target = '__blank';
                          a.href = response.data.link;
                          a.click();
                          this.$router.push(`/assessments/${this.assessment.id}`)
                        } else {
                          this.$toast.error('Something went wrong!');
                        }
                    })
                    .catch(err => {
                        this.$toast.error(err);
                    })
                    .finally(e => {
                        NProgress.done();
                        this.isSending = false;
                    });
            },
            loadAssessment: function (id, estimateId) {
                NProgress.start();
                this.assessmentId = id;
                this.estimateId = estimateId;
                Axios.get(
                estimateId
                      ? '/ir/assessment/estimate/' + estimateId + '/for-report'
                      : '/ir/assessment/' + id + '/for-report'
                )
                    .then((response) => {
                        if (response.data && response.data._status) {
                            this.report = response.data.report;
                            this.images = response.data.images;
                            this.files = response.data.files;
                            this.pdfs = response.data.pdfs;
                            this.numberToUse = response.data.number;
                            this.assessment = response.data.assessment;
                        }
                    })
                    .catch((error) => {
                        console.log('error:', error);
                    })
                    .finally(() => NProgress.done());
            },
        },
        computed: {
            ...mapGetters([
                'addressStates',
                'addressCountries',
            ]),
            ifUpdatedReport() {
                return !!(this.report.status == 'updated');
            },
            isSupReport(){
              return this.estimateId ? true : false;
            },
        },
        mounted: function () {
            this.assessmentId = this.$route.params.assessmentId;
            this.estimateId   = this.$route.params.estimateId;
            this.loadAssessment(this.$route.params.assessmentId, this.$route.params.estimateId);
        },
        components: {
            NumberFormatter,
            GoodWizard,
            Multiselect,
            ImageSelector,
        }
    };

</script>

<template>
    <div class="claimlinq-assessment-print-report">
        <div class="page-header">
            <h4>Print {{ isSupReport ? 'Supplement' : '' }} Assessment Report</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/assessments">Assessments</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/assessments/' + this.assessment.id">Assessment {{report.assessmentNumber}}</router-link>
                </li>
                <li class="breadcrumb-item active">Print {{ isSupReport ? 'Supplement' : '' }} Assessment Report</li>
            </ol>
        </div>
        <div class="claimlinq-assessment-print-report-body">
            <good-wizard
                    :steps="steps"
                    :currentStep="step"
                    ref="printAssessmentReportStepper"
            >
                <div slot="assessment-print-report-step-1" class="assessment-print-report-step-1">
                    <div class="header">
                        Review the {{ isSupReport ? 'Supplement' : '' }} Assessment report details
                    </div>
                    <div class="subheader">
                    </div>
                    <div class="assessment-print-report assessment-print-report-check">
                        <div class="card" style="margin-top: 30px;">
                            <div class="card-block bg-white" style="padding-left:0px;">
                                <div class="form-group row">
                                    <label class="col-sm-12 col-12 col-form-label " style="font-size:14px;">Assessment information</label>
                                </div>
                            </div>
                        </div>
                        <div class="claim-block bg-white">
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Assessment Number</label>
                                <div class="col-sm-7 col-7" style="padding-top: calc(0.375rem + 1px);padding-bottom: calc(0.375rem + 1px);">
                                    {{ numberToUse }}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Report Creation Date</label>
                                <div class="col-sm-7 col-7" style="padding-top: calc(0.375rem + 1px);padding-bottom: calc(0.375rem + 1px);">
                                    {{report.date|formatDate}}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Report Status</label>
                                <div class="col-sm-7 col-7" style="padding-top: calc(0.375rem + 1px);padding-bottom: calc(0.375rem + 1px);">
                                    <span v-if="report.status == 'new'">New Report</span>
                                    <span v-else-if="this.report.status == 'updated'">Updated <span style="color:red; padding-left:10px;">Original Creation date: </span>{{report.statusDateCreate|formatDate}}</span>
                                    <span v-else></span>
                                </div>
                            </div>
                            <div v-if="!report.hasInvoice && !isSupReport" class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Create Invoice</label>
                                <div class="col-sm-7 col-7">
                                    <label class="form-check-inline checkbox">
                                        <input v-model="report.invoice"
                                               class="form-check-input" type="checkbox"
                                               :value="true">
                                        <span class="icon"><i class='bx bx-check'></i></span>
                                        <span class="text">Yes</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div slot="assessment-print-report-step-2">
                    <div class="header">
                        Select Images
                    </div>
                    <div class="subheader">
                        Choose the images to submit by ticking the box next to the image.
                    </div>
                    <div class="assessment-print-report assessment-print-report-images">
                        <image-selector :images="images" :path="''" @onChange="onImageSelect"></image-selector>

                    </div>

                </div>

                <div slot="assessment-print-report-step-3">
                    <div class="header">
                        Select Files
                    </div>
                    <div class="subheader">
                        Choose the files to submit by ticking the box next to the file.
                    </div>
                    <div class="assessment-print-report assessment-print-report-files">
                        <div class="box box-block">
                            <div class="row">
                                <table class="table b-table select-parts">
                                    <thead class="bg-navyblue">
                                    <tr>
                                        <th class="header-check">
                                            <label class="form-check-inline checkbox">
                                                <input @click="toggleSelectAllFiles" class="form-check-input" type="checkbox" v-model="allFilesSelected">
                                                <span class="icon"><i class='bx bx-check'></i></span>
                                            </label>
                                        </th>
                                        <th class="header-name" style="text-align: left;">Select All</th>
                                        <th class="header-name"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="file in files">
                                        <td>
                                            <label class="form-check-inline checkbox">
                                                <input class="form-check-input" type="checkbox" v-model="file.selected">
                                                <span class="icon"><i class='bx bx-check'></i></span>
                                            </label>
                                        </td>
                                        <td>{{ file.name }}</td>
                                        <td>
                                            <strong>Status:</strong>
                                            <span v-show="!file.selected" class="text-muted">Not attached</span>
                                            <strong v-show="file.selected">To be attached</strong>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div class="subheader">
                            Choose to add a copy of the Estimate PDF
                        </div>
                        <div class="box box-block">
                            <div class="row">
                                <table class="table b-table select-parts">
                                    <thead class="bg-navyblue">
                                    <tr>
                                        <th class="header-check">
                                            <label class="form-check-inline checkbox">
                                                <input @click="toggleSelectAllEstimateFiles" class="form-check-input" type="checkbox" v-model="allEstimateFilesSelected">
                                                <span class="icon"><i class='bx bx-check'></i></span>
                                            </label>
                                        </th>
                                        <th class="header-name" style="text-align: left;">Select All</th>
                                        <th class="header-name"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-if="pdfs.original">
                                      <td>
                                        <label class="form-check-inline checkbox">
                                          <input class="form-check-input" type="checkbox" v-model="pdfsInfo.original.selected">
                                          <span class="icon"><i class='bx bx-check'></i></span>
                                        </label>
                                      </td>
                                      <td>{{ pdfs.original.name }}</td>
                                      <td>
                                        <strong>Status: </strong>
                                        <span v-show="!pdfsInfo.original.selected" class="text-muted">{{ pdfsInfo.original.messageNotAttached }}</span>
                                        <strong v-show="pdfsInfo.original.selected">{{ pdfsInfo.original.messageAttached }}</strong>
                                      </td>
                                    </tr>
                                    <tr v-if="pdfs.adjusted">
                                      <td>
                                        <label class="form-check-inline checkbox">
                                          <input class="form-check-input" type="checkbox" v-model="pdfsInfo.adjusted.selected">
                                          <span class="icon"><i class='bx bx-check'></i></span>
                                        </label>
                                      </td>
                                      <td>{{ pdfs.adjusted.name }}</td>
                                      <td>
                                        <strong>Status: </strong>
                                        <span v-show="!pdfsInfo.adjusted.selected" class="text-muted">{{ pdfsInfo.adjusted.messageNotAttached }}</span>
                                        <strong v-show="pdfsInfo.adjusted.selected">{{ pdfsInfo.adjusted.messageAttached }}</strong>
                                      </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>

            </good-wizard>
            <div class="wizard__buttons">
                <button v-if="$refs.printAssessmentReportStepper && $refs.printAssessmentReportStepper.currentStep != 0"
                        class="btn btn-outline-primary button-back"
                        type="button"
                        @click="$refs.printAssessmentReportStepper.goBack()">
                    Back
                </button>
                <button v-if="$refs.printAssessmentReportStepper && $refs.printAssessmentReportStepper.currentStep == 0"
                        class="btn btn-outline-primary button-cancel"
                        type="button"
                        @click="clickCancel">
                    Cancel
                </button>
                <button v-if="$refs.printAssessmentReportStepper && $refs.printAssessmentReportStepper.currentStep != 0"
                        class="btn btn-outline-primary button-cancel"
                        type="button"
                        @click="clickCancel">
                    Cancel
                </button>
                <button v-if="$refs.printAssessmentReportStepper && $refs.printAssessmentReportStepper.currentStep != 2"
                        class="btn btn-primary button-next"
                        type="button"
                        @click="$refs.printAssessmentReportStepper.goNext()"
                        :tabindex="4">
                    Next
                </button>
                <button v-if="$refs.printAssessmentReportStepper && $refs.printAssessmentReportStepper.currentStep == 2"
                        class="btn btn-primary button-claim"
                        type="button"
                        @click="clickSend"
                        :disabled="isSending"
                >
                    Print Report
                </button>
            </div>
        </div>
    </div>
</template>
<style>
    .V3 .new-claim {
        max-width: 1028px;
        margin: 0 auto;
    }
</style>
<style lang="scss" scoped>
    .claimlinq-assessment-print-report-body ::v-deep .wizard__steps {
        width: 900px;
    }
    .claimlinq-assessment-print-report-body ::v-deep .wizard__body {
        width: 900px;
    }
    .claimlinq-assessment-print-report-body ::v-deep .wizard__body .assessment-print-report-step-1 {
        width: 573px;
        margin: 0px auto 0 auto;
    }

    .wizard .wizard__body table.b-table tr th,
    .wizard .wizard__body table.b-table tr td {
        /*padding: 13px 15px;*/
        padding-left: 15px;
        padding-right: 15px;
    }

    .grey-color {
        opacity: 0.6;
    }

    .claimlinq-assessment-print-report-body .assessment-print-report .header-check {
        /*padding: 0.75rem;*/
        width: 35px;
        min-width: 35px;
    }

    .claimlinq-assessment-print-report-body .assessment-print-report .header-name {
        line-height: 35px;
    }
</style>
<style scoped>

    .V3 .claimlinq-assessment-print-report-body > .claim-block {
        padding: 0px;
    }

    .V3 .claimlinq-assessment-print-report-body .claim-block {
        padding: 0px;
    }

    .V3 .wizard__buttons {
        display: flex;
        position: relative;
        padding: 15px 0px 75px 0px;
        flex-flow: row wrap;
        justify-content: flex-end;
        max-width: 573px;
        margin: 0 auto;
    }

    .V3 .wizard__buttons .btn {
        flex: 0 1 120px;
        margin-left: 15px;
        margin-bottom: 15px;
        min-width: 82px;
        padding: 10px;
    }

    .V3 .wizard__buttons .btn:first-child {
        margin-right: auto;
        margin-left: 0px;
    }

    .V3 .wizard__buttons .btn:last-child {
        margin-right: 0;
    }

    .V3 .wizard__buttons .button-cancel {
        border-color: rgba(28, 31, 57, 0.25);
        color: #1C1F39;
    }

    .V3 .wizard__buttons .button-cancel:hover,
    .V3 .wizard__buttons .button-cancel:focus {
        background-color: rgba(28, 31, 57, 0.62);
        color: white;
    }

    @media screen and (max-width: 625px) {
        .V3 .wizard__buttons {
            padding: 15px 15px 75px 15px;
            width: 100%;
        }

        .V3 .wizard__buttons .btn {
            flex-grow: 1;
            font-size: 13px;
            font-weight: bold;
            line-height: 18px;
            height: 39px;
        }

        .V3 .wizard__buttons .button-claim-estimate {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100%;
            margin-left: 0px;
        }
    }

    @media screen  and (max-width: 320px) {
        .V3 .wizard__buttons .btn:nth-child(3) {
            margin-left: 0px;
        }
    }
</style>
