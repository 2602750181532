<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-4 col-block">
        <block-other-party-details :isAssessmentsPage="true"
                                   :details="otherParty.details"
                                   :vehicleOwnerNames="vehicleOwnerNames"
                                   :hideProps="['licenseNumber','licenseCountry', 'licenseState']"
                                   :isReadonly="!isEditable">

        </block-other-party-details>
      </div>
      <div class="col-12 col-md-4 col-block">
        <block-other-party-vehicle :vehicle="otherParty.vehicle" :isReadonly="!isEditable">

        </block-other-party-vehicle>
      </div>
      <div class="col-12 col-md-4 col-block">
        <block-assessment-information
            :assessment="assessment"
            :legal-firms="legalFirms"
            :assessorsList="assessorsList"
            @new-legal-firm="$emit('new-legal-firm', arguments[0])"
        >
        </block-assessment-information>
        <block-repairer-totals :all-items="allItems" :insurer="otherParty.insurer"
                               :isShow="(assessment && assessment.type==='other_party')"></block-repairer-totals>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 col-block">
        <block-claims-insurer :insurer="otherParty.insurer" :isReadonly="!isEditable">

        </block-claims-insurer>
      </div>
      <div class="col-12 col-md-4 col-block">
        <block-claims-repairer-new
            :repairer="otherParty.repairer || undefined"
            :isReadonly="true"
            :inner-repairer="otherParty.innerRepairer || undefined"
            :change-not-allowed="true"
            :show-approved-status="true"
            :show-estimators="false"
            @on-repairer-select="onRepairerUpdate"
            :estimator="otherParty.estimator"
        >
        </block-claims-repairer-new>
      </div>
      <div class="col-12 col-md-4 col-block">
        <block-other-party-repairer-quote
            :repairerQuote="otherParty.repairerQuote"
            :isReadonly="true"
            :settled="otherParty.settled"
            :assessment-tab-info="assessmentTab.info"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 ">
        <block-accident-details :isEditable="isEditableAccidentDetails" :details="otherParty.accident.details">

        </block-accident-details>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import BlockOtherPartyDetails from "../../../components/block-other-party-details";
import BlockVehicle from "@/components/block-vehicle";
import BlockClaimsInsurer from "@/components/block-claims-insurer";
import BlockClaimsRepairer from "@/components/block-claims-repairer";
import BlockClaimsRepairerNew from "@/components/block-claims-repairer-new";
import BlockOtherPartyRepairerQuote from "@/components/block-other-party-repairer-quote";
import BlockOtherPartyVehicle from "@/components/block-other-party-vehicle";
import BlockRepairerTotals from "../../../components/block-repairer-totals";
import NumberFormatter from '@/components/number-formatter';
import AdvancedSearch from "@/components/utility/advanced-search";
import BlockAssessmentInformation from "../../../components/blocks/assessment/block-assessment-information";
import BlockAccidentDetails from "@/components/block-accident-details";
import {mapGetters} from 'vuex';
import Axios from "axios";

export default {
  name: 'assesment-view-other-party-tab',
  props: {
    'otherParty': Object,
    'assessment': Object,
    'onSelectAssessor': Function,
    'assessorsList': Array,
    'allItems': [Array, null],
    'legalFirms': Array,
    isEditableAccidentDetails: Boolean,
    'isEditable': Boolean,
    vehicleOwnerNames: {
      type: Array,
      default() {
        return [];
      }
    },
    assessmentTab: {
      type: Object,
      default() {
        return {
          info: {
            quoteNumber: "",
            quoteDate: null,
          },
          vehicle: {},
          assessment: {},
        };
      },
    },
  },
  data: function () {
    return {};
  },
  computed: {
    countOtherParty() {
      return this.otherParties.length || 0;
    },
  },
  methods: {
    onRepairerUpdate(repairer) {
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/ir/claim/${this.assessment.claimId}`,
        data: {
          otherParties: [{
            id: this.otherParty.id,
            repairer: {
              innerRepairerId: repairer.id
            }
          }]
        },
      })
          .then(response => {
            if (response && response.status === 200 && response.data && response.data._status) {
              this.$emit('on-other-party-repairer-updated');
            }
          })
          .catch(error => console.log(error));
    },
  },
  components: {
    BlockAssessmentInformation,
    BlockRepairerTotals,
    BlockOtherPartyVehicle,
    BlockOtherPartyRepairerQuote,
    BlockClaimsRepairer,
    BlockClaimsInsurer,
    BlockVehicle,
    BlockOtherPartyDetails,
    NumberFormatter,
    AdvancedSearch,
    BlockAccidentDetails,
    BlockClaimsRepairerNew,
  },

};
</script>
<style scoped>
.V3 body.V3 .multiselect__select {
  height: 35px;
  right: 0px;
  top: 0px;
}

.V3 .col-form-label {
  padding: 8px 0;
}

@media (max-width: 575.98px) {
  .V3 .col-block .col-form-label {
    padding: 8px 15px;
  }
}
</style>
