<template>
    <div class="adaptive-input font-12 d-flex " :class="adaptClass">
        <label v-if="label" 
               class="adaptive-input__label font-nunito--bold"
               :class="readonly ? 'mx-0' : 'mr-2 mb-2'">{{ label }} :</label>
        <template v-if="maxStringLengthAchieved(value) || expandInput || expanded">
            <b-textarea autofocus
                        ref="textarea"
                        v-if="!readonly"
                        :rows="expanded ? 4 : 2"
                        :value="value"
                        @input="handleInput"
                        max-rows="10"
                        class="adaptive-input__textarea m-0 ps-scroll"
                        @keyup.esc="row.toggleDetails"
                        @keyup="handleKeyup"
                        @keydown="handleKeydown"
                        @blur="handleInputBlur"
                        :placeholder="placeholder">
            </b-textarea>
            <div v-else class="adaptive-input__textarea d-block w-100 m-0 ps-scroll">{{ value }}</div>
        </template>
        
        <b-input autofocus
                v-else
                ref="input"
                type="text"
                :plaintext="readonly"
                :value="value"
                @input="handleInput"
                class="adaptive-input__input m-0 font-12"
                :class="{'p-0': readonly}"
                @keydown.enter="expand"
                @keyup.esc="$emit('on-esc')"
                @keyup="handleKeyup"
                @keydown="handleKeydown"
                @blur="handleInputBlur"
                :placeholder="placeholder">
        </b-input>
    </div>
</template>

<script>
export default {
    props: [
        'value',
        'label',
        'placeholder',
        'expanded',
        'readonly'
    ],
    data() {
        return {
            maxStringLength: 32 - this.label.length,
            expandInput: false,
            cmd: false,
        }
    },
    computed: {
        longLabel() {
            return this.label.length >= 18;
            // return this.maxStringLength <= 15;
        },
        adaptClass() {
            return {
                'align-items-start': this.maxStringLengthAchieved(this.value) || this.expandInput,
                'align-items-center': !this.maxStringLengthAchieved(this.value) && !this.expandInput,
                'flex-wrap': this.longLabel || this.expandInput || this.maxStringLengthAchieved(this.value),
            }
        },
    },
    mounted() {
        if (!this.expanded && !this.readonly) {
            if (this.$refs.input) {
                const inputText = this.$refs.input.$el;
                if (inputText) {
                    this.maxStringLength = Math.floor(inputText.offsetWidth / 7);
                }
            }
        }
    },
    methods: {
        maxStringLengthAchieved(str) {
            return str && str.length > this.maxStringLength;
        },
        expand() {
            this.expandInput = true;
            const len = this.maxStringLength - this.value.length;
            const remains = new Array(len + 1).join(' ');

            this.$nextTick(() => {
                this.$emit('input', `${this.value}${remains}\n`);
            });
        },
        handleInput(_val) {
            this.$emit('input', _val);
        },
        handleKeyup(e) {
            switch(e.keyCode) {
                case 224:   // cmd on firefox
                case 91:    // left cmd on webkit(chrome/safari)
                case 93:    // right cmd on webkit(chrome/safari)
                case 17:    // ctrl
                    {
                        this.cmd = false;
                        break;
                    }
                case 13:    // enter
                    {
                        if (this.cmd) {
                            e.preventDefault();
                        }
                    }
            }
        },
        handleKeydown(e) {
            switch (e.keyCode) {
                case 224:   // cmd on firefox
                case 91:    // left cmd on webkit(chrome/safari)
                case 93:    // right cmd on webkit(chrome/safari)
                case 17:    // ctrl
                    {
                        this.cmd = true;
                        break;
                    }
                case 13: // enter
                    {   
                        if (this.cmd) {
                            e.preventDefault();
                            this.$emit('ctrlEnter', this.value);
                        }
                    }
            }
        },
        handleInputBlur() {
            this.$emit('ctrlEnter', this.value);
        }
    },
}
</script>

<style lang="scss" scoped>
    .adaptive-input {
        margin-bottom: 5px;
        &__label {
            margin-top: 1px;
            white-space: nowrap;
            min-width: 108px;
        }
        &__item {
            display: flex;
        }
        &__textarea,
        &__input {
            position: relative;
            top: -3px;
            margin-left: 5px;
            max-width: 25vw;
            border: 1px !important;
            // padding: 4px 5px !important;
        }
        &__input,
        &__textarea {
            // border: 0px !important;
            font-weight: normal !important;

            &:active,
            &:focus {
                background-color: var(--white) !important;
                box-shadow: none !important;
            }
        }
        .form-control-plaintext {
            background-color: transparent !important;
        }
    }
</style>