<template>
    <div class="block-uploader">
        <div class="images-tab files-upload block">

            <div class="row">
                <div class="col-12 col-lg-8">
<!--                    <div class="card-header bg-navyblue">-->
<!--                        Images-->
<!--                    </div>-->
                    <image-lightbox :isRemove="true"
                                    :isEdit="false"
                                    :isDownload="true"
                                    :isPreviewImage="true"
                                    :position="'col pl-0 pr-0'"
                                    :imageList="imageList"
                                    :path="''"
                                    @removeImage="deleteImage"
                                    @changeImageCategory="changeImageCategory"
                    />
                </div>
                <div class="col-12 col-lg-4">
                    <file-upload
                            :api-urls="urls"
                            :files-accepted="'image/png, image/jpeg'"
                            :value="[]"
                            :object-id="0"
                            :dynamic-width="true"
                            :after-upload-function-override="afterUpload"
                            :after-upload="afterUploadEmitted"
                    />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    /* eslint-disable*/
    import ImageLightbox from "@/components/image-lightbox/image-lightbox";
    import FileUpload from "@/components/FileUpload";
    import Axios from "axios";

    export default {
        name: 'images-tab',
        components: {
            ImageLightbox,
            FileUpload,
        },
        props: {
            imageList: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        mounted() {

        },
        data() {
            return {};
        },
        computed: {
            urls() {
                return {uploadFile: `/ir/assessment/${this.$route.params.assessmentId}/add-image`};
            },
        },
        methods: {
            changeImageCategory(item){
                this.$emit('changeImageCategory', item);
            },
            deleteImage(item) {
                console.log(item);
                if (!confirm(`Confirm you want to DELETE this photo`)) {
                    return;
                }
                //console.log(item);
                Axios.post(`/ir/assessment/${this.$route.params.assessmentId}/remove-file`, {fileId: item.id})
                    .then(response => {
                        if (response.data && response.data._status) {
                            let index = _.findIndex(this.imageList, el => Number(el.id) === Number(item.id));
                            console.log(index, item);
                            this.imageList.splice(index, 1);

                            this.$toast.success('Image removed!');
                            // this.$emit('fileUploaded');
                        } else {
                            this.$toast.error('Unable to remove image');
                        }
                    })
                    .catch(e => {
                        this.$toast.error('Unable to remove image');
                        console.error(e);
                    });
            },
            afterUpload() {
               // console.log('Image uploded');
                this.$toast.success('Image uploaded');
                this.$emit('imageUploaded');
            },
            afterUploadEmitted() {
                //console.log('Image uploded2');
              //  this.$toast.success('Image uploaded');
                this.$emit('imageUploaded');
            },
        },
    };
</script>


<style scoped>
    .images-tab {
        max-width: 1300px;
    }

    i.bx-trash {
        font-size: 20px;
        color: red;
        cursor: pointer;
    }

    i.bx-download {
        font-size: 20px;
        color: #00B050;
        cursor: pointer;
        margin-right: 10px;
    }

    i.bx-camera {
        font-size: 42px;
        color: #00B050;
        cursor: pointer;
    }

    a {
        color: #212529;
    }

    a:hover {
        color: #00B050;
        text-decoration: underline;
    }

    .block-uploader .files-upload {
        /*width: 864px;*/
        /* margin-left: 15px; */
        /* margin-top: 15px; */
    }

    .block-uploader .files-upload {
        width: 100%;
        /* padding: 15px 15px 0 15px; */
        margin-left: unset;
        margin-right: unset;
        margin-top: unset;

    }

    @media screen and (min-width: 993px) {
        .block-uploader .files-upload {
            /*width: 864px;*/
            padding: unset;
            /* margin-left: 15px; */
            /* margin-top: 15px; */
        }

        .pagination-block-paginator {
            right: auto !important;
            left: 1000px !important;
        }
    }

</style>

