<script>
import Axios from 'axios';
import BlockPagination from '../../../components/utility/block-pagination';

export default {
  name: 'notes',
  props: ['notes', 'search', 'card_id', 'addNoteTrigger', 'assessmentId'],
  watch: {
    addNoteTrigger(){
      this.addNote();
    },
  },
  data: function () {
    return {
      note: '',
      note_id: null,
      filter: '',
      sortBy: null,
      sortDesc: false,
      fields:[
        {
          label: "Note Details",
          key: "id",
          sortable: true,
          tdClass: 'card-note'
        },
        {
          label: "Created By",
          key: "createdBy",
          sortable: true,
          tdClass: 'created-by'
        },
        {
          label: "Date",
          key: "createdOn",
          sortable: true,
          tdClass: 'created-on'
        },
        {
          label: "Action",
          key: "action",
          tdClass: 'action'
        }
      ],
      cardsPerPage: 10,
      cardsCurrentPage: 1,
    };
  },
  methods: {
    filterNote(noteText, query){
        let note = '';
        if (!query) {
            note = noteText;
        } else {
            note = noteText.replaceAll(new RegExp(query, 'iug'), `<span style="background-color: yellow">${query}</span>`);
        }
        note = note.replace(/(?:\n)/g, '<br />');
        return note;
    },
    loadNotes() {
      this.$emit('loadNotes');
    },
    addNote: function () {
      this.note_id = null;
      this.note = '';
      this.$bvModal.show('noteBoxModal');
    },
    editNote: function (note) {
      this.note_id = note.id;
      this.note = note.note;
      this.note = this.note.replace(/<br \/>/g, "");
      this.$bvModal.show('noteBoxModal');
    },
    removeNote: function (note) {
      NProgress.start();
      if (note.id == null) {
        return;
      }
      let url = `/ir/assessment/${note.assessmentId}/remove-note/${note.id}`;
      Axios({
        method: 'post',
        responseType: 'json',
        url: url,
        validateStatus: function (status) {
          return status < 500;
        },
        data: {}
      })
          .then(response => {
            if (response.status == 200 && response.data && response.data._status) {
              this.$toast.success("Removed");
              this.loadNotes();
            } else {
              this.$toast.error("Error");
            }
            NProgress.done();
          })
          .catch(error => {
            NProgress.done();
            this.$toast.error(error);
          });
    },
    saveNote: function () {
      NProgress.start();
      let url = '';

      if (this.note_id == null) {
        url = `/ir/assessment/${this.assessmentId}/notes`;
      } else {
        url = `/ir/assessment/${this.assessmentId}/edit-note/${this.note_id}`;
      }
      Axios({
        method: 'post',
        responseType: 'json',
        url: url,
        validateStatus: function (status) {
          return status < 500;
        },
        data: JSON.stringify({note: this.note})
      })
          .then(response => {
            if (response.status == 200 && response.data && response.data._status) {
              this.$toast.success("Success");
              if (this.note_id == null) {
                this.note_id = response.data.noteId;
              }
              this.loadNotes();
            } else {
              this.$toast.error("Error");
            }
            NProgress.done();
          })
          .catch(error => {
            NProgress.done();
            this.$toast.error(error);
          }).finally(() => {
            this.$bvModal.hide('noteBoxModal');
      });
    },
  },
  computed: {
    filteredNotes(){
      if(!this.search){
        return this.notes;
      }
      let data = [];
      //this.notes.forEach(n => {
      //  let f = this.filterNote(n.filteredNote, this.search);
      //  if(f != n.filteredNote){
      //    data.push(n);
      //  }
      //})
      this.notes.forEach(n => {
        let f = this.filterNote(n.note, this.search);
        if(f != n.note){
          data.push(n);
        }
      })
      return data;
    },
  },
  components: {
    BlockPagination,
  },
};
</script>

<template>
  <div class="tab-content-table">
    <div class="row">
      <div class="col-12">
        <div class="notes-table use-pagination-block-bottom">
          <div class="rs-btn-group float-sm-right mb-1">
          </div>
          <b-table
              id="card-notes-table"
              responsive
              hover
              :items="filteredNotes"
              :fields="fields"
              :current-page="cardsCurrentPage"
              :per-page="cardsPerPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
          >
            <template v-slot:head()="data">
              <div v-if="!data.field.sortable">{{data.label}}</div>
              <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
            </template>
            
            <template v-slot:cell(id)="data">
    <!--          <div v-html="filterNote(data.item.filteredNote, search)"></div>-->
              <div v-html="filterNote(data.item.note, search)"></div>
            </template>
            <template v-slot:cell(createdBy)="data">
              <div>{{data.item.createdBy.firstName}} {{data.item.createdBy.lastName}}</div>
            </template>
            <template v-slot:cell(createdOn)="data">
              <div>{{data.item.createdOn|formatDatetime}}</div>
            </template>
            <template v-slot:cell(action)="data">
              <div class="d-flex">
                <div style="display: inline-block;">
                  <a href=""
                    @click.prevent="editNote(data.item)"
                    data-toggle="modal"
                    data-target="#noteBoxModal"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Edit Note', placement:'left'}">
                    <i class='bx bx-pencil'></i>
                  </a>
                </div>
                <div style="display: inline-block; margin-left:15px;">
                    <a href=""
                      @click.prevent="removeNote(data.item)"
                      style="width: 19px; height: 19px;"
                      class="nav-link btn_trush"
                      v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Delete Note', placement:'left'}">
                    </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      </div>

      <block-pagination
          :role="'Notes'"
          :arrayOfSomeone="notes"
          :arrayOfSomeoneFiltered="filteredNotes"
          :currentPage="cardsCurrentPage"
          @changedCurrentPage="value => cardsCurrentPage = value"
          :perPage="cardsPerPage"
          @changedPerPage="value => cardsPerPage = value"
      >
      </block-pagination>

    <b-modal :centered="true" id="noteBoxModal" ref="noteBoxModal" aria-labelledby="noteboxModalLabel" header-class="modal-header-background" footer-bg-variant="light" style="top: 10vh">
          <template v-slot:modal-header>
            <h4 class="modal-title" id="noteboxModalLabel">{{note_id ? 'Edit Note' : 'New Note'}}</h4>
            <button @click="$bvModal.hide('noteBoxModal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" style="color:#fff;">&times;</span>
            </button>
          </template>
          <template v-slot:default class="modal-body">
            <div class="form-group">
              <label for="message-text" class="form-control-label">Note:</label>
              <textarea v-model="note" class="form-control" id="message-text" rows="10" style="height: auto;"></textarea>
            </div>
          </template>
          <template v-slot:modal-footer class="modal-footer">
            <button @click="saveNote" type="button" class="btn btn-black btn-green-colored"><i class="ti-save"></i> Save Note</button>
            <button @click="$bvModal.hide('noteBoxModal')" type="button" class="btn btn-secondary" data-dismiss="modal"><i class="ti-close"></i>Close</button>
          </template>
    </b-modal>

  </div>
</template>

<style>
.modal-header-background {
  background: #00b050;
  color: white;
}
.btn-green-colored {
  background: #00b050;
  border: 1px solid #009945;
}
.btn-green-colored:hover {
  background: #00cc5c;
  border: 1px solid #00b050;
}

.V3 .notes-table #card-notes-table tbody tr td {
  vertical-align: top;
}
.V3 .notes-table #card-notes-table tbody tr {
  margin: 0 15px;
  border-bottom: 1px solid rgba(27,30,56,0.1);
}
.V3 .notes-table #card-notes-table tbody {
  /* position: relative; */
}
.V3 .notes-table #card-notes-table tbody:before,
.V3 .notes-table #card-notes-table tbody:after {
  content: '';
  display: inline-block;
  width: 15px;
  height: 100%;
  background-color: #ffffff;
  /* background-color: aqua; */
  position: absolute;
  left: 0;
  top: 120px;
}
.V3 .notes-table #card-notes-table tbody:after {
  left: unset;
  right: 0;
}
.V3 .new-note-btn{
  position: fixed;
  top: 146px;
  right: 0;
  z-index: 1000;
  font-family: "Nunito Sans";
  font-size: 13px;
  font-weight: bold;
}

.notes-table table th:first-child {
  min-width: 250px;
  width: 60%;
}

/* resize height - identical to files-tab - files-tab.vue */
.notes-table table#card-notes-table.table th {
  height: 40px;
  padding: 0.75rem 1.25rem;
  border-top: 0;
  border-bottom: 0;
  white-space: nowrap;
}

.notes-table table.table tbody > tr:first-child td {
  border-top: 0;
}

/* note details width */
.notes-table .note-details {
  min-width: 250px;
  width: 60%;
}

.V3 .notes-table .ti-plus {
  margin-right: 5px;
  font-weight: bold
}

.V3 .notes-table .bx-pencil {
  font-size: 19px;
  color: black;
}
.V3 .notes-table .notes-table {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.V3 .notes-table #card-notes-table tr td {
  padding: 16px 15px 14.5px 15px;
}

.V3 .notes-table #card-notes-table tr td:first-child {
  /* padding-right:80px; */
}

.V3 .notes-table .note-action a {
  color: #1B1E38;
}

.V3 .notes-table .note-action > a {
  font-size: 19px;
}

.V3 .notes-table .card-note {
  max-width: 250px;
  width: 400px;
  overflow-wrap: break-word;
  font-size: 12px;
  font-weight: 600;
}

.V3 .notes-table .created-by {
  max-width: 265px;
  width: 265px;
  font-size: 12px;
  font-weight: bold;
}

.V3 .notes-table .created-on {
  max-width: 265px;
  width: 265px;
  font-size: 12px;
  font-weight: 600;
}

.V3 .notes-table .action {
  max-width: 180px;
  width: 180px;
}

</style>
