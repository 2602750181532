<script>
    /* eslint-disable */
    import advancedSearchWithNew from "@/components/utility/advancedSearchWithNew";
    import BlockAddress from "./block-address";

    export default {
        name: 'block-insured',
        props: {
            owners: {
                type: Array,
                default: () => []
            },
            insured: {
                type: Object,
                default: function(){
                    return {
                        name: '',
                        type: 'I',
                        contact: '',
                        email: '',
                        address: {},
                    }
                }
            },
        },
        data: function () {
            return {
              localInsured: {},
            };
        },
        watch: {
          insured(){
            this.localInsured = this.insured;
          },
        },
        computed: {
          compInsured: {
            get(){
              return this.localInsured;
            },
            set(data){
              this.localInsured = data;
            },
          }
        },
        methods: {
          onCustomerSelect(e){
            console.log('CUSTOMER SELECT', e)
            if(e === null){
              this.addNewCustomer(this.compInsured.name);
              return;
            }
            this.selectAnotherCustomer(e.id);
          },
          onCustomerUpdateName(e){
            console.log('CUSTOMER UPDATE NAME', e)
            this.renameCurrentCustomer(e);
          },
          addNewCustomer(name){
            this.$emit('on-add-new-insured', { name })
          },
          selectAnotherCustomer(id){
            this.$emit('on-select-another-insured', { id })
          },
          renameCurrentCustomer(name){
            this.$emit('on-rename-current-insured', { name })
          },
          updateCurrentCustomer(){
            this.$emit('on-update-current-insured', {

            })
          }
        },
        components: {
            advancedSearchWithNew,
            BlockAddress,
        }

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Insured</strong>
        </div>
        <div class="card-block bg-white">
            <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">Vehicle Owner</label>
                <div class="col-sm-8 col-8">
                  <advanced-search-with-new ref="compInsured.name"
                                            searchLabel="Customer"
                                            v-model="compInsured.name"
                                            :searchData="owners"
                                            searchKey="name"
                                            @onSelect="onCustomerSelect"
                                            @onUpdate="onCustomerUpdateName"
                                            :placeholderText="'Owner Name'"
                                            :isDisabled="true"
                  >
                  </advanced-search-with-new>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Owner Type</label>
                <div class="col-sm-8 col-8">

                    <label class="form-check-inline radio">
                        <input v-model="compInsured.type"
                               class="form-check-input" type="radio" name="owner_type"
                               id="individual_customer_type" value="I" disabled>
                        <span class="icon"><i class='bx bx-check'></i></span><span
                            class="text">Individual</span>

                    </label>
                    <label class="form-check-inline radio">
                        <input v-model="compInsured.type"
                               class="form-check-input" type="radio" name="owner_type"
                               id="business_customer_type" value="B" disabled>
                        <span class="icon"><i class='bx bx-check'></i></span><span
                            class="text">Business</span>
                    </label>
                </div>
            </div>


            <div class="form-group row">
                <label for="contact" class="col-sm-4 col-4 col-form-label">Contact Nbr</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="compInsured.contact" class="form-control" id="contact" placeholder="Contact Nbr" readonly>
                </div>
            </div>


            <div class="form-group row">
                <label for="email" class="col-sm-4 col-4 col-form-label">Email</label>
                <div class="col-sm-8 col-8">
                    <input type="email" v-model="compInsured.email" class="form-control" id="email" placeholder="Email" readonly>
                </div>
            </div>

            <block-address :address="compInsured.address" :isReadonly="true">

            </block-address>

        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
