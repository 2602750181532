<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-4 pr-0 col-block">
        <block-other-party-details :isAssessmentsPage="true"
                                   :details="insured.insured"
                                   :vehicleOwnerNames="vehicleOwnerNames"
                                   :hideProps="['licenseNumber','licenseCountry', 'licenseState']"
                                   :isReadonly="!isEditable">

        </block-other-party-details>
        <!--                <insured-block :insured="insured.insured"></insured-block>-->
      </div>
      <div class="col-12 col-md-4 pr-0 col-block">
        <block-other-party-vehicle :vehicle="insured.vehicle" :isReadonly="!isEditable">

        </block-other-party-vehicle>
        <!--                <vehicle-block :vehicle="insured.vehicle"></vehicle-block>-->
      </div>
      <div class=" col-12 col-md-4 col-block">
        <block-assessment-information
            :assessment="assessment"
            :legal-firms="legalFirms"
            :assessorsList="assessorsList"
            @new-legal-firm="$emit('new-legal-firm', arguments[0])"
        ></block-assessment-information>
        <block-repairer-totals
            :all-items="allItems"
            :insurer="insured.insurer"
            :isShow="(assessment && assessment.type==='insured')"
        ></block-repairer-totals>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 col-block">
        <block-claims-insurer :insurer="insured.insurer" :isReadonly="!isEditable"></block-claims-insurer>
      </div>
      <div class="col-12 col-md-4 col-block">
        <block-claims-repairer-new
            :inner-repairer="insured.innerRepairer || undefined"
            :repairer="insured.repairer || undefined"
            :isReadonly="true"
            :change-not-allowed="true"
            @on-repairer-select="onRepairerUpdate"
            :estimator="insured.estimator"
            :estimators="estimators"
            :show-approved-status="true"
            :show-estimators="false"></block-claims-repairer-new>
      </div>
      <div class="col-12 col-md-4 col-block">
        <block-other-party-repairer-quote
            :repairerQuote="insured.repairer"
            :assessment-tab-info="assessmentTab.info"
            :settled="insured.insured.settled"
            :isReadonly="true"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 ">
        <block-accident-details :isEditable="isEditableAccidentDetails" :details="insured.accident.details">

        </block-accident-details>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import BlockOtherPartyDetails from "../../../components/block-other-party-details";
import BlockOtherPartyVehicle from "@/components/block-other-party-vehicle";
import VehicleBlock from "../../../components/block-vehicle";
import InsuredBlock from "../../../components/block-insured";
import BlockClaimsInsurer from "../../../components/block-claims-insurer";
import BlockClaimsRepairer from "../../../components/block-claims-repairer";
import BlockClaimsRepairerNew from "@/components/block-claims-repairer-new";
import BlockOtherPartyRepairerQuote from "../../../components/block-other-party-repairer-quote";
import BlockRepairerTotals from "../../../components/block-repairer-totals";
import NumberFormatter from '@/components/number-formatter';
import AdvancedSearch from "@/components/utility/advanced-search";
import BlockAssessmentInformation from "../../../components/blocks/assessment/block-assessment-information";
import BlockAccidentDetails from "@/components/block-accident-details";
import Axios from "axios";

export default {
  name: 'assessment-view-insured-tab',
  props: {
    vehicleOwnerNames: {
      type: Array,
      default() {
        return [];
      }
    },
    insured: {
      type: Object,
      default() {
        return {
          insured: {
            address: {},
            reasons: {},
            settled: {},
          },
          insurer: {},
          repairer: {},
          vehicle: {},
          innerRepairer: {},
        };
      },
    },
    'assessment': Object,
    assessmentTab: {
      type: Object,
      default() {
        return {
          info: {
            quoteNumber: "",
            quoteDate: null,
          },
          vehicle: {},
          assessment: {},
        };
      },
    },
    'onSelectAssessor': Function,
    'assessorsList': Array,
    'allItems': [Array, null],
    'legalFirms': Array,
    'innerRepairer': Object,
    isEditableAccidentDetails: Boolean,
    isEditable: Boolean,
    'estimators': Array,
  },
  data: function () {
    return {};
  },
  computed: {},
  methods: {
    onRepairerUpdate(repairer) {
      return;
    },
  },
  components: {
    VehicleBlock,
    BlockClaimsInsurer,
    BlockOtherPartyRepairerQuote,
    InsuredBlock,
    BlockClaimsRepairer,
    BlockClaimsRepairerNew,
    BlockRepairerTotals,
    NumberFormatter,
    AdvancedSearch,
    BlockAssessmentInformation,
    BlockAccidentDetails,
    BlockOtherPartyDetails,
    BlockOtherPartyVehicle,
  }
};
</script>
