<script>
    /* eslint-disable */
    import NumberFormatter from '@/components/number-formatter';
    import _ from "lodash";

    export default {
        name: 'block-repairer-totals',
        props: {
            allItems: {
                type: Array,
                default() {
                    return [];
                }
            },
            insurer: {
                excess: 0,
                contribution: 0,
            },
            isShow: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            worksCost() {
                if (!this.isShow) {
                    return '';
                }
                let sum = 0;
                if (this.allItems) {
                    _.forEach(this.allItems, function (i) {
                      //  console.log('i',i)
                        let itemlist = i.itemsList;
                        if (itemlist) {
                            for (let type in itemlist) {
                                if (['RR', 'Repair', 'Paint', 'Sublet'].includes(type)) {
                                    itemlist[type].forEach(item => {
                                        sum += parseFloat((parseFloat(item.hours) * parseFloat(item.price)).toFixed(2));
                                    });
                                }
                                if (['Materials', 'Other'].includes(type)) {
                                    itemlist[type].forEach(item => {
                                        sum += parseFloat((parseFloat(item.price)).toFixed(2));
                                    });
                                }
                            }
                        }
                    });
                }
                sum *= 1.1;
                return parseFloat(sum.toFixed(2));
            },
            partsCost() {
                if (!this.isShow) {
                    return '';
                }
                let sum = 0;
                if (this.allItems) {
                    _.forEach(this.allItems, function (i) {
                        let itemlist = i.itemsList;
                        if (itemlist && itemlist.Part) {
                            itemlist.Part.forEach(item => {
                                sum += parseFloat((parseFloat(item.qty) * parseFloat(item.price)).toFixed(2));
                            });
                        }
                    });
                }
                sum *= 1.1;
                return parseFloat(sum.toFixed(2));
            },
            lessExcess() {
                if (!this.isShow) {
                    return '';
                }
                return parseFloat(((parseFloat(this.insurer.excess) || 0)).toFixed(2));
            },
            totalCost() {
                if (!this.isShow) {
                    return '';
                }
                return parseFloat((this.worksCost + this.partsCost - this.lessExcess).toFixed(2));
            },
        },
        components: {
            NumberFormatter,
        }
    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Repair Totals</strong>
        </div>
        <div class="card-block bg-white">
            <div class="form-group row">
                <label for="otherPartyWorksCost" class="col-sm-4 col-4 col-form-label">Labour & Paint, Misc & Sublets, Incl Tax</label>
                <div class="col-sm-8 col-8">
                    <number-formatter id="otherPartyWorksCost" v-model="worksCost" type="text" class="form-control numeric" format="$0,0.00" readonly></number-formatter>
                </div>
            </div>
            <div class="form-group row">
                <label for="otherPartyPartsCost" class="col-sm-4 col-4 col-form-label">Parts, Incl Tax</label>
                <div class="col-sm-8 col-8">
                    <number-formatter id="otherPartyPartsCost" v-model="partsCost" type="text" class="form-control numeric" format="$0,0.00" readonly></number-formatter>
                </div>
            </div>
            <div class="form-group row">
                <label for="otherPartyLessExcess" class="col-sm-4 col-4 col-form-label">Less - Excess</label>
                <div class="col-sm-8 col-8">
                    <number-formatter id="otherPartyLessExcess" v-model="lessExcess" type="text" class="form-control numeric" format="$0,0.00" readonly></number-formatter>
                </div>
            </div>
            <div class="form-group row">
                <label for="otherPartyTotalCost" class="col-sm-4 col-4 col-form-label">Total</label>
                <div class="col-sm-8 col-8">
                    <number-formatter id="otherPartyTotalCost" v-model="totalCost" type="text" class="form-control numeric" format="$0,0.00" readonly></number-formatter>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
